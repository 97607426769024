import React from "react";
import { Routes, Route } from "react-router-dom";

import Result from "antd/lib/result";
import { ProvidersRoutesRef } from "constants/routes";
import { ProvidersLayout } from "layouts/providersLayout";
import { Messages } from "pages/messages";
import { PatientMessages } from "pages/patientMessages";
import { Patients } from "pages/patients";
import { Providers } from "pages/providers";
import { ProviderDetails } from "pages/providerDetails";
import { RemoteVitals } from "pages/remoteVitals";
import { CallRecords } from "pages/callRecords";
import { Notifications } from "pages/notifications";
import { LaboratoryPage } from "pages/laboratory/laboratoryPage";
import { Careguidelines } from "pages/careguidelines";
import { RiskProfile } from "pages/riskProfile";
import { PatientProfile } from "pages/patientProfile";
import { CallLogs } from "pages/callLogs";
import { CareTeam } from "pages/careteam";
import { TasksPage } from "pages/tasks/tasks";
import { MedicationsPage } from "pages/medications";
import { Symptoms } from "pages/symptoms";
import { FluidMetrics } from "pages/fluidMetrics";
import { Billing } from "pages/billing";
import { Appointment } from "pages/appointment";
import { PerformanceAndUtilization } from "pages/performance";
import { AllNotifications } from "pages/allNotifications";
import { TopbarExtension } from "components/topbarExtension";
import { Images } from "pages/images";
import { CarePlan } from "pages/carePlan";
import { MedicationProvider } from "contextApis/medicationContext";
import { BillingProvider } from "pages/billing/billingContext";
import { PatientListProvider } from "contextApis/patientsListContext";
import { SurveysPage } from "pages/surveys/surveys";
import { RespondPage } from "pages/surveys/respond/respond";
import { SurveyProvider } from "contextApis/surveyContext";
import { SurveyReports } from "pages/surveyReports";
import { Dashboard } from "pages/dashboard";
import Allbillings from "pages/billings/allBillings";
import { Diagnosis } from "pages/diagnosis";

export const ProviderRoutes: React.FC = () => {
  return (
    <div>
      <ProvidersLayout>
        <Routes>
          <Route path={`/`} element={<Patients />} />
          <Route
            path={`/${ProvidersRoutesRef.Patients}`}
            element={
              <PatientListProvider>
                <Patients />
              </PatientListProvider>
            }
          />
          <Route
            path={`/${ProvidersRoutesRef.Providers}`}
            element={<Providers />}
          />
          <Route
            path={`/${ProvidersRoutesRef.Messages}`}
            element={<Messages />}
          />
          <Route
            path={`/${ProvidersRoutesRef.Dashboard}`}
            element={<Dashboard />}
          />
          <Route
            path={`/${ProvidersRoutesRef.Billings}`}
            element={<Allbillings />}
          />
          <Route
            path={`/${ProvidersRoutesRef.PatientMessages}/:id`}
            element={<PatientMessages />}
          />
          <Route
            path={`/${ProvidersRoutesRef.Laboratory}/:id`}
            element={<LaboratoryPage />}
          />
          <Route
            path={`/${ProvidersRoutesRef.Careguidelines}/:id`}
            element={<Careguidelines />}
          />
          <Route
            path={`/${ProvidersRoutesRef.Tasks}/:id`}
            element={<TasksPage />}
          />
          <Route
            path={`/${ProvidersRoutesRef.Medications}/:id`}
            element={
              <MedicationProvider>
                <MedicationsPage />
              </MedicationProvider>
            }
          />
          <Route
            path={`/${ProvidersRoutesRef.Providers}/:role/:id`}
            element={<ProviderDetails />}
          />
          <Route
            path={`/${ProvidersRoutesRef.RemoteVitals}/:id`}
            element={<RemoteVitals />}
          />
          <Route
            path={`/${ProvidersRoutesRef.CallRecords}/:id`}
            element={<CallRecords />}
          />
          <Route
            path={`/${ProvidersRoutesRef.Notifications}/:id`}
            element={<Notifications />}
          />
          <Route
            path={`/${ProvidersRoutesRef.AllNotifications}`}
            element={<AllNotifications />}
          />
          <Route
            path={`/${ProvidersRoutesRef.RiskProfile}/:id`}
            element={<RiskProfile />}
          />
          <Route
            path={`/${ProvidersRoutesRef.PatientProfile}/:id`}
            element={<PatientProfile />}
          />
          <Route
            path={`/${ProvidersRoutesRef.CallLogs}/:id`}
            element={<CallLogs />}
          />
          <Route
            path={`/${ProvidersRoutesRef.CareTeam}/:id`}
            element={<CareTeam />}
          />
          <Route
            path={`/${ProvidersRoutesRef.Symptoms}/:id`}
            element={<Symptoms />}
          />
          <Route
            path={`/${ProvidersRoutesRef.Performance}/:id`}
            element={<PerformanceAndUtilization />}
          />
          <Route
            path={`/${ProvidersRoutesRef.FluidMetrics}/:id`}
            element={<FluidMetrics />}
          />
          <Route
            path={`/${ProvidersRoutesRef.Survey}/:id`}
            element={
              <SurveyProvider>
                <SurveysPage />
              </SurveyProvider>
            }
          />
          <Route
            path={`/${ProvidersRoutesRef.Respond}/:id`}
            element={
              <SurveyProvider>
                <RespondPage />
              </SurveyProvider>
            }
          />
          <Route
            path={`/${ProvidersRoutesRef.ViewForm}/:type/:id`}
            element={
              <SurveyProvider>
                <RespondPage />
              </SurveyProvider>
            }
          />
          <Route
            path={`/${ProvidersRoutesRef.Billing}/:id`}
            element={
              <BillingProvider>
                <Billing />
              </BillingProvider>
            }
          />
          <Route
            path={`/${ProvidersRoutesRef.Appointment}/:id`}
            element={<Appointment />}
          />
          <Route
            path={`/${ProvidersRoutesRef.CarePlan}/:id`}
            element={<CarePlan />}
          />
          <Route
            path={`/${ProvidersRoutesRef.Images}/:id`}
            element={<Images />}
          />
          <Route
            path={`/${ProvidersRoutesRef.SurveyReports}`}
            element={<SurveyReports />}
          />
          <Route
            path={`/${ProvidersRoutesRef.Diagnosis}/:id`}
            element={<Diagnosis />}
          />
          <Route
            path="*"
            element={
              <>
                <TopbarExtension />
                <Result
                  status="404"
                  title="404"
                  subTitle="Sorry, the page you visited does not exist."
                />
              </>
            }
          />
        </Routes>
      </ProvidersLayout>
    </div>
  );
};
