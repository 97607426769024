import React, { useContext, useEffect, useMemo, useState } from "react";
import { useQuery } from "react-query";
import "./App.css";
import "./components/table/table.css";
import { Routes, Route, useNavigate, useLocation } from "react-router";
import LoginPage from "./pages/login/loginPage";
import {
  CommonRoutesRef,
  ProvidersRoutesRef,
  PublicRoutesRef,
  PatientsRoutesRef
} from "./constants/routes";
import { UserRoles } from "./constants/roles";
import ResetPasswordPage from "./pages/login/resetPasswordPage";
import jwtDecode from "jwt-decode";
import { UserContext, UserContextState } from "./contextApis/userContext";
import { RoutesContainer } from "./routes/RoutesContainer";
import moment from "moment";
import configureAmplify from "./amplify/configureAmplify";
import { MeetingPortalModal } from "./components/meetingPortal/Modal/meetingPortalModal";
import ConsentPage from "pages/consent/consentPage";
import LaunchEpic from "pages/epic/launch";
import RedirectEpic from "pages/epic/redirect";
import styled from "styled-components";
import { LoginBackgroundIcon } from "pages/login/loginbackground";
import { AppCard } from "components/card";
import { Col, Space } from "antd";
import { CareGemIcon } from "pages/login/careGemIcon";
import { Colors } from "helpers/colors";
import { AppButton } from "components/button";
import { ButtonType } from "components/button/appButton";
import { CloseIcon } from "svgIcons/closeIcon";
import { PatientEducation } from "pages/patientEducation";
import axios from "axios";
import message from "antd/lib/message";

import { useSearchParams } from "react-router-dom";
import { createApiClient } from "apiClient";
import { VCMeetPage } from "routes/VCMeetPage";
import ReactDOM from "react-dom";
import Modal from "./pages/bpAlerts/modal";
import FinalizeCallModal from "pages/patients/finalizeCallModal";

export interface JWTToken {
  readonly auth_time: number;
  readonly client_id: string;
  readonly "cognito:groups": string[];
  readonly event_id: string;
  readonly exp: number;
  readonly iat: number;
  readonly iss: string;
  readonly jti: string;
  readonly origin_jti: string;
  readonly scope: string;
  readonly sub: string;
  readonly token_use: string;
  readonly username: string;
}

const App: React.FC = () => {
  const { isLoading, isFetching } = useQuery("configure-amplify", async () => {
    return configureAmplify();
  });
  const userContext = useContext<UserContextState>(UserContext);
  const navigate = useNavigate();
  const params = new URLSearchParams(window.location.search);
  sessionStorage.setItem("platform", "");
  if (params.get("access_token")) {
    sessionStorage.setItem("access_token", params.get("access_token") || "");
  }

  if (params.get("platform") || params.get("platform") === "") {
    sessionStorage.setItem("platform", params.get("platform") || "");
  }
  // console.log(sessionStorage.getItem("platform"));

  if (params.get("user_role")) {
    sessionStorage.setItem("userRole", params.get("user_role") || "");
  }
  const accessToken = sessionStorage.getItem("access_token");

  const token = "e1ec20b9-641d-467c-a09b-771e19cc74ab";

  useEffect(() => {
    const userIdentityId = sessionStorage.getItem("userIdentityId");
    if (userIdentityId) {
      userContext.onUserIdentityIdChange(userIdentityId);
    }
  }, []);

  useEffect(() => {
    if (accessToken) {
      const decoded: JWTToken = jwtDecode(accessToken);
      if (moment().valueOf() < decoded.exp * 1000) {
        userContext.onLogin();
        // navigate(getInitialPath(window.location.pathname));
      } else {
        userContext.onLogout();
        navigate("/");
      }
    } else {
      if (window.location.pathname in PublicRoutesRef) {
        return;
      } else {
        return;
      }
      // if (window.location.pathname === `/consent`) return }

      //navigate(`/consent/:consentToken`);
    }
  }, [accessToken]);

  const isAmplifyConfiguring = useMemo(
    () => isLoading || isFetching,
    [isLoading, isFetching]
  );

  const currLocation = useLocation();

  const [url, setUrl] = useState<string>("");

  const consentPath = (currLocation.pathname + currLocation.search).replace(
    /&/g,
    "%26"
  );

  useEffect(() => {
    currLocation.search && setUrl(`app://caregem.org${consentPath}`);
  }, []);

  const [searchParams] = useSearchParams();

  const [authenticatedToken, setAuthenticatedToken] = useState<string>("");

  const [onlyForVCMeet, setOnlyForVCMeet] = useState<string | boolean>(false);

  const currLocation2 = useLocation();

  const [token2, setToken2] = useState<string | undefined>();

  useEffect(() => {
    if (currLocation2.search) {
      const params = new URLSearchParams(currLocation2.search);
      const tokenValue = params.get("token");
      if (tokenValue) {
        const decodedTokenValue = decodeURIComponent(
          tokenValue.replace(/\+/g, " ")
        );
        console.log(decodedTokenValue);
        setToken2(decodedTokenValue);
      }
    }
  }, [currLocation2]);

  useEffect(() => {
    if (token2) {
      setTimeout(() => {
        verifyVCToken(token2);
      }, 470);
    }
  }, [token2]);

  const verifyVCToken = async (token_data: string) => {
    if (currLocation2.search) {
      const params = new URLSearchParams(currLocation2.search);
      const tokenValue = params.get("token");
      if (tokenValue) {
        const decodedTokenValue = decodeURIComponent(
          tokenValue.replace(/\+/g, " ")
        );
        setToken2(decodedTokenValue);
      }
    }
    try {
      const response = await axios.post(
        `${process.env["REACT_APP_API_ENDPOINT"]}/consent/verify/meeting_token?token_data=${token_data}`
      );

      console.log("Response:", response.data.message);
      if (response.data && response.data.message === "Token Verified") {
        message.success("Loggedd In.!!");
        setAuthenticatedToken(response.data.token);
      }
      if (response.data && response.data.message === "Token Expired") {
        message.warn("Token Expired");
      }
      if (response.data && response.data.message === "Invalid Token") {
        message.error("Invalid Token.!!");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };
  useEffect(() => {
    if (!sessionStorage.getItem("userId")) {
      sessionStorage.setItem("showPhoneNumberPopup", "false");
    }
  }, []);

  useEffect(() => {
    if (authenticatedToken) {
      const decoded: JWTToken = jwtDecode(authenticatedToken);
      console.log(decoded);
      if (Date.now() < decoded.exp * 1000) {
        getUserData(authenticatedToken)
          .then(data => {
            sessionStorage.setItem("access_token", authenticatedToken);
            sessionStorage.setItem("userRole", data.role);
            sessionStorage.setItem(
              "billingPermission",
              data.billing_permission
            );
            sessionStorage.setItem("userId", data.internal_id);
            sessionStorage.setItem("userName", data.userName);
            userContext.onLogin();
            navigate("/VCMeetPage");
          })
          .catch(error => {
            console.error(error);
          });
      } else {
        setOnlyForVCMeet(false);
        userContext.onLogout();
        navigate("/");
      }
    } else {
      setOnlyForVCMeet(false);
      if (location.pathname in PublicRoutesRef) {
        // Handle public routes if necessary
      } else {
        // Handle private routes if necessary
      }
    }
  }, [authenticatedToken]);

  async function getUserData(accessToken: string) {
    const response = await axios.get(
      `${process.env["REACT_APP_API_ENDPOINT"]}/users/auth`,
      {
        headers: {
          Authorization: `${accessToken}`
        }
      }
    );
    return response.data;
  }

  function isMobileDevice() {
    const urlParams = new URLSearchParams(window.location.search);
    const action = urlParams.get("action");
    const isEmailChange = action === "email_change";

    const screenWidth =
      window.innerWidth ||
      document.documentElement.clientWidth ||
      document.body.clientWidth;

    const mobileWidthThreshold = 768;

    if (isEmailChange) return;
    return screenWidth < mobileWidthThreshold;
  }

  const handleClose = () => {
    // setIsMobile(!isMobile);
    userContext.setIsMobile(!userContext.isMobile);
    sessionStorage.setItem("isMobile", String(!userContext.isMobile));
  };

  if (userContext.isMobile ? isMobileDevice() : !isMobileDevice()) {
    return (
      <LoginBackground style={{ display: "grid", placeItems: "center" }}>
        <AppCard
          cardHeight={"300px"}
          cardWidth={"90%"}
          style={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            overflow: "scroll"
          }}
        >
          <span style={{ marginLeft: "92%" }} onClick={handleClose}>
            <CloseIcon />
          </span>
          <Col span={24} style={{ textAlign: "center", padding: 20 }}>
            <CareGemIcon logoHeight={54} logoWidth={54} />
            <div style={{ fontSize: "18px" }} color={Colors.Black}>
              <b>CareGem</b>
            </div>
            <a href={url}>
              <AppButton
                type={ButtonType.Primary}
                buttonContent={<Space size={4}>Open With App</Space>}
                style={{
                  width: "180px",
                  marginTop: "calc(15% - 4vw)"
                }}
              />
            </a>
          </Col>
        </AppCard>
        <div
          style={{
            width: "100vw",
            height: "100vh",
            display: "grid",
            placeItems: "center",
            overflow: "hidden"
          }}
        >
          <LoginBackgroundIcon />
        </div>
      </LoginBackground>
    );
  } else {
    return userContext.isAuthenticated ? (
      <>
        <RoutesContainer />
        <MeetingPortalModal />
        <Modal />
        <FinalizeCallModal />
      </>
    ) : (
      <>
        <Routes>
          <Route
            path="/"
            element={<LoginPage isAmplifyConfiguring={isAmplifyConfiguring} />}
          />
          <Route
            path={`/${CommonRoutesRef.ForgotPassword}`}
            element={<ResetPasswordPage />}
          />
          <Route
            path={`/${PublicRoutesRef.OauthLaunch}`}
            element={<LaunchEpic />}
          />
          <Route
            path={`/${PublicRoutesRef.OauthRedirect}`}
            element={<RedirectEpic />}
          />
          <Route
            path="/consent"
            element={
              <ConsentPage
                current_route=""
                consentCheckRefetch={() => {
                  return null;
                }}
              />
            }
          />
          {/* <Route
            path={PatientEducation}
            element={
              <ConsentPage
                current_route=""
                consentCheckRefetch={() => {
                  return null;
                }}
              />
            }
          /> */}
        </Routes>
      </>
    );
  }
};

export default App;

const LoginBackground = styled.div`
  width: 100vw;
  height: 100vh;
  background: linear-gradient(173.57deg, #06bfe3 0%, #208cff 100%);
  box-shadow: 0 3px 8px 0 rgba(6, 157, 222, 0.5);
`;
