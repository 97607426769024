import { Col, message, Row, Space } from "antd";
import moment from "moment";
import React from "react";
import { useMutation } from "react-query";
import styled from "styled-components";
import { createApiClient } from "apiClient";
import { Colors } from "helpers/colors";
import { DateTimeFormat } from "helpers/dateTimeFormat";
import { NotificationListItem } from "sharedTypes";
import { AppAvatar } from "components/avatar";
import { CircleIndicator } from "components/circleIndicator";
import { AppSwitch } from "components/switch";
import { ProvidersRoutesRef } from "constants/routes";
import { useNavigate } from "react-router";

interface NotificationsMenuItem {
  readonly avatarColSpan?: number;
  readonly notificationItem: NotificationListItem;
  readonly getNotificationsList: () => void;
  readonly loading?: boolean;
  readonly switchColSpan: number;
}

export const NotificationsMenuItem: React.FC<NotificationsMenuItem> = ({
  avatarColSpan = 3,
  getNotificationsList,
  notificationItem,
  loading,
  switchColSpan
}) => {
  const { mutate: markNotification, isLoading: isLoadingMarkNotification } =
    useMutation("mark-notification", createApiClient().putNotifications, {
      onSuccess: () => {
        getNotificationsList();
      },
      onError: () => {
        message.error("There was a problem updating notifications.");
      }
    });

  const messageColSpan = 24 - avatarColSpan - switchColSpan;

  const onSwitchToggle = (
    identifier: string | number,
    type: string,
    state: string
  ) => {
    markNotification({
      notificationId: identifier,
      body: {
        status: state === "read" ? "unread" : "read",
        type: type === "careteam-survey" ? "care_team" : type
      }
    });
  };

  let notificationMsg = notificationItem.desc;
  const char = ":";
  const index = notificationMsg.indexOf(char);

  if (index !== -1) {
    const splicedString =
      notificationMsg.slice(0, index + 1) +
      " " +
      notificationMsg.slice(index + 1);
    notificationMsg = splicedString;
  }

  const navigate = useNavigate();

  enum RedirectsTypes {
    CareTeam = "care_team",
    CareTeamSurvey = "careteam-survey",
    Billing = "careteam-billing",
    Medication = "medications",
    Message = "messages",
    RemoteVital = "remote_vitals",
    Survey = "survey",
    Symptoms = "symptoms"
  }

  const redirect = (redirects: string) => {
    console.log("redirects", redirects);
    switch (redirects) {
      case RedirectsTypes.CareTeam:
        return ProvidersRoutesRef.CareTeam;
      case RedirectsTypes.CareTeamSurvey:
        return ProvidersRoutesRef.Survey;
      case RedirectsTypes.Billing:
        return ProvidersRoutesRef.Billing;
      case RedirectsTypes.Medication:
        return ProvidersRoutesRef.Medications;
      case RedirectsTypes.Message:
        return `/${ProvidersRoutesRef.Messages}`;
      case RedirectsTypes.RemoteVital:
        return ProvidersRoutesRef.RemoteVitals;
      case RedirectsTypes.Survey:
        return ProvidersRoutesRef.Survey;
      case RedirectsTypes.Symptoms:
        return ProvidersRoutesRef.Symptoms;
      default:
        console.log("Invalid Redirect Type");
    }
  };

  const redirectUser = (item: any) => {
    // console.log("redirect", redirect(item?.type));
    onSwitchToggle(item.notification_id, item.type, item.status);

    item?.type === "messages"
      ? navigate(`${redirect(item?.type)}`)
      : navigate(`${redirect(item?.type)}/${item?.patient_id}`);

    setTimeout(() => {
      window.location.reload();
    }, 100);
  };

  return (
    <NotificationWrapper>
      <AvatarCol span={avatarColSpan}>
        <AppAvatar imageSrc={""} size="large" />
      </AvatarCol>
      <ContentCol
        span={messageColSpan}
        onClick={() => redirectUser(notificationItem)}
      >
        <MessageUpperRow>{notificationMsg}</MessageUpperRow>
        <MessageLowerRow>
          <Space size={5}>
            <DateTimeFormat
              date={moment.utc(notificationItem.created_on).valueOf()}
            />
            {notificationItem.severity === 1 &&
              notificationItem.status === "unread" && (
                <CircleIndicator
                  outerColor={"#fbddb7"}
                  innerColor={"#f18e13"}
                />
              )}
            {notificationItem.severity === 2 &&
              notificationItem.status === "unread" && (
                <CircleIndicator
                  outerColor={"#EEB5AD"}
                  innerColor={Colors.Red}
                />
              )}
          </Space>
        </MessageLowerRow>
      </ContentCol>
      <SwitchCol span={switchColSpan}>
        <AppSwitch
          tooltip="Mark as unread"
          altTooltip="Mark as read"
          checked={notificationItem.status === "unread"}
          onSwitchToggle={() => {
            onSwitchToggle(
              notificationItem.notification_id,
              notificationItem.type,
              notificationItem.status
            );
          }}
          identifier={notificationItem.notification_id}
          color={Colors.Red}
          loading={isLoadingMarkNotification || loading}
        />
      </SwitchCol>
      <hr
        style={{
          width: "97%",
          position: "absolute",
          bottom: -7.5,
          left: "1.5%",
          backgroundColor: Colors.Lavender,
          height: "2px",
          border: "none"
        }}
      />
    </NotificationWrapper>
  );
};

const AvatarCol = styled(Col)`
  text-align: center;
  margin-top: -4px;
  height: 40px;
  display: grid;
  place-items: center;
`;

const SwitchCol = styled(Col)`
  text-align: center;
  padding: 0px 15px 0px 0px;
`;

const ContentCol = styled(Col)`
  margin-top: -7px;
`;

const MessageUpperRow = styled(Row)`
  font-size: 14px;
  margin-top: 2px;
  width: 100%;
  display: inline-block;
  overflow-wrap: break-word;
`;

const MessageLowerRow = styled(Row)`
  color: ${Colors.gothicRegular};
  font-size: 12px;
`;

const NotificationWrapper = styled.div`
  &:hover {
    cursor: pointer;
    background-color: ${Colors.GhostWhite};
  }
  width: 100%;
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
`;
