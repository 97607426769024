import { SearchOutlined } from "@ant-design/icons";
import { Input, message, Spin, Select, Checkbox } from "antd";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { createApiClient } from "apiClient";
import { AppButton } from "components/button";
import { ButtonType } from "components/button/appButton";
import { AppCard } from "components/card";
import { AppModal } from "components/modal";
import {
  PatientContext,
  PatientContextState
} from "contextApis/patientContext";
import { CommonPatientExtensionItems } from "pages/patients/commonPatientExtensionItems";
import React, { useContext, useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";
import styled from "styled-components";

export const DiagnosisPage: React.FC = () => {
  const [searchValue, setSearchValue] = useState<string>("");
  const [diagnosisOptions, setDiagnosisOptions] = useState<any[]>([]);
  const [selectedDiagnosis, setSelectedDiagnosis] = useState<any | null>(null);
  const [patientDiagnoses, setPatientDiagnoses] = useState<any[]>([]);
  const [checkedItems, setCheckedItems] = useState<string[]>([]);
  const [enableModal, setEnableModal] = useState<boolean>(false);

  const patientContext = useContext<PatientContextState>(PatientContext);

  const { isLoading: isLoadingPatientDiagnosis } = useQuery(
    "patient-diagnosis-data",
    async () => {
      return createApiClient().getPatientDiagnosis(
        sessionStorage.getItem("patientId") || ""
      );
    },
    {
      onSuccess(data) {
        setPatientDiagnoses(data || []);
      }
    }
  );

  useQuery(
    ["patientDiagnoses", patientContext?.orgId],
    async () => {
      return createApiClient().getAllDiagnosesList(patientContext?.orgId);
    },
    {
      enabled: !!patientContext?.orgId,
      onSuccess(data) {
        setDiagnosisOptions(data || []);
      }
    }
  );

  const { mutate: addDiagnosis } = useMutation(
    "add-patient-diagnosis",
    () =>
      createApiClient().postPatientDiagnosis(
        sessionStorage.getItem("patientId") || "",
        selectedDiagnosis.code
      ),
    {
      onSuccess(data: any) {
        message.success("successfully added.");
      }
    }
  );

  const { mutate: removeDiagnosis } = useMutation(
    "delete-patient-diagnosis",
    () =>
      createApiClient().deletePatientDiagnosis(
        sessionStorage.getItem("patientId") || "",
        checkedItems
      ),
    {
      onSuccess(data: any) {
        message.success("successfully removed.");
      }
    }
  );

  const handleAddDiagnosis = () => {
    if (!selectedDiagnosis) return;
    setPatientDiagnoses([...patientDiagnoses, selectedDiagnosis]);
    addDiagnosis();
    setSelectedDiagnosis(null);
  };

  const handleRemoveDiagnosis = () => {
    if (!checkedItems) return;

    const filteredDiagnoses = patientDiagnoses.filter(
      diagnosis =>
        !checkedItems.includes(diagnosis.assigned_code) &&
        !checkedItems.includes(diagnosis.code)
    );

    setPatientDiagnoses([...filteredDiagnoses]);
    setCheckedItems([]);
    removeDiagnosis();
  };

  const toggleCheckbox = (event: CheckboxChangeEvent, code: string) => {
    const { checked } = event.target;
    setCheckedItems(prev =>
      checked ? [...prev, code] : prev.filter(item => item !== code)
    );
  };

  return (
    <>
      <CommonPatientExtensionItems />
      <DiagnosisWrapper>
        <DiagnosisTitle>Patient Diagnoses</DiagnosisTitle>
        <AppCard cardWidth="100%" cardHeight="auto">
          <DiagnosisHeader>
            <InputStyled
              placeholder={"Search Diagnosis"}
              prefix={<StyledSearch />}
              onChange={e => setSearchValue(e.target.value)}
              allowClear
            />
            <AppButton
              onClick={() => setEnableModal(true)}
              buttonContent="Add Diagnosis"
              type={ButtonType.Primary}
            />
          </DiagnosisHeader>
          <AppCard cardWidth="100%" cardHeight="auto">
            {isLoadingPatientDiagnosis ? (
              <LoaderContainer>
                <Spin size="large" />
              </LoaderContainer>
            ) : patientDiagnoses.length === 0 ? (
              <NoDataText>No diagnosis added.</NoDataText>
            ) : (
              <DiagnosisList>
                {patientDiagnoses
                  .filter(diagnosis =>
                    diagnosis.abbreviated_desc
                      .toLowerCase()
                      .includes(searchValue.toLowerCase())
                  )
                  .map((diagnosis, index) => (
                    <DiagnosisItem key={index}>
                      {diagnosis.abbreviated_desc} (
                      {diagnosis.assigned_code || diagnosis.code})
                    </DiagnosisItem>
                  ))}
              </DiagnosisList>
            )}
          </AppCard>
        </AppCard>
      </DiagnosisWrapper>

      {/* Add Diagnosis Modal */}
      <AppModal
        title="Add Diagnosis"
        visible={enableModal}
        width="50%"
        footer={false}
        onCancel={() => setEnableModal(false)}
      >
        <DiagnosisHeader>
          <Select
            style={{ width: "60%" }}
            placeholder="Select Diagnosis"
            value={selectedDiagnosis?.code}
            onChange={code => {
              const diagnosis = diagnosisOptions.find(d => d.code === code);
              setSelectedDiagnosis(diagnosis || null);
            }}
          >
            {diagnosisOptions.map((diagnosis, index) => (
              <Select.Option key={index} value={diagnosis.code}>
                {diagnosis.abbreviated_desc} ({diagnosis.code})
              </Select.Option>
            ))}
          </Select>
          <AppButton
            onClick={handleAddDiagnosis}
            buttonContent="Add Diagnosis"
            type={ButtonType.Primary}
            disabled={!selectedDiagnosis}
          />
          <AppButton
            onClick={handleRemoveDiagnosis}
            buttonContent="Remove Diagnosis"
            type={ButtonType.Primary}
            disabled={checkedItems.length === 0}
          />
        </DiagnosisHeader>
        <AppCard cardWidth="100%" cardHeight="auto">
          {isLoadingPatientDiagnosis ? (
            <Spin size="large" />
          ) : patientDiagnoses.length === 0 ? (
            <NoDataText>No diagnosis added.</NoDataText>
          ) : (
            <DiagnosisList>
              {patientDiagnoses.map((diagnosis, index) => (
                <DiagnosisItem key={index}>
                  <Checkbox
                    checked={checkedItems.includes(diagnosis.assigned_code)}
                    onChange={event =>
                      toggleCheckbox(event, diagnosis.assigned_code)
                    }
                  />
                  {diagnosis.abbreviated_desc} (
                  {diagnosis.assigned_code || diagnosis.code})
                </DiagnosisItem>
              ))}
            </DiagnosisList>
          )}
        </AppCard>
      </AppModal>
    </>
  );
};

const DiagnosisWrapper = styled.div`
  margin-top: 18px;
`;

const DiagnosisTitle = styled.div`
  font-size: 14px;
  font-weight: bold;
  padding-bottom: 18px;
`;

const DiagnosisHeader = styled.div`
  display: flex;
  gap: 20px;
  margin-bottom: 10px;
`;

const InputStyled = styled(Input)`
  height: 45px;
  width: 90%;
  border: 1px solid #e2edf3;
  border-radius: 10px;
`;

const StyledSearch = styled(SearchOutlined)`
  color: #979dab;
  width: 16px;
`;

const DiagnosisList = styled.ul`
  list-style: none;
  padding: 0;
`;

const DiagnosisItem = styled.li`
  padding: 10px;
  border-bottom: 1px solid #e2edf3;
  display: flex;
  align-items: center;
  gap: 10px;
`;

const NoDataText = styled.div`
  text-align: center;
  color: gray;
  padding: 10px;
`;
const LoaderContainer = styled.div`
  text-align: center;
  color: gray;
  padding: 10px;
`;
