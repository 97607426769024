import React, { useContext, useEffect, useRef, useState } from "react";
import Space from "antd/lib/space";
import Row from "antd/lib/row";
import Col from "antd/lib/col";
import Spin from "antd/lib/spin";
import Form from "antd/lib/form";
import message from "antd/lib/message";
import styled from "styled-components";
import moment, { Moment } from "moment";
import { CloseOutlined } from "@ant-design/icons";

import { Colors } from "helpers/colors";
import { AppDatePicker, AppSelect } from "components/inputs";
import { AppButton } from "components/button";
import { ButtonType } from "components/button/appButton";
import { BillDiagnosisCheckboxDropdown } from "./BillDiagnosisDropdown";
import { BillChargesCheckboxDropdown } from "./BillChargesDropdown";
import { useMutation, useQuery } from "react-query";
import { createApiClient } from "apiClient";
import { CommonErrorMessage } from "components/CommonErrorMessage";
import {
  PatientContext,
  PatientContextState
} from "contextApis/patientContext";
import { UserContext, UserContextState } from "contextApis/userContext";
import get from "lodash/get";
import { AllBillsList, CheckboxMenuCommonInterface } from "sharedTypes";
import { BillingContext, BillingContextState } from "./billingContext";
import { customRound, dateToUTC } from "helpers/utils";
import { Button, Dropdown, Tabs } from "antd";
import { MenuProps } from "rc-menu";
import { AppModal } from "components/modal";
import { CallLogsList } from "pages/callLogs/callLogs";
import { CallLogsTable } from "pages/callLogs/callLogsTable";
import { CallRecord, CallRecordList } from "pages/callRecords/CallRecordList";
import { useParams } from "react-router";
import {
  MeetingContextState,
  MeetingModalContext,
  sessionStatusValues
} from "contextApis/meetingModalContext";
import { SelectedCallRecordDraft } from "pages/callRecords/callRecords";
import { left } from "styled-system";

interface AddBillProps {
  readonly showAddBill: boolean;
  getBillingList(): void;
  setShowAddBill(show: boolean): void;
  readonly latestApprovedBill?: AllBillsList;
  readonly enableBilling: boolean;
}

export const AddBill: React.FC<AddBillProps> = ({
  getBillingList,
  setShowAddBill,
  latestApprovedBill,
  enableBilling
}) => {
  const [form] = Form.useForm();
  const patientContext = useContext<PatientContextState>(PatientContext);
  const userContext = useContext<UserContextState>(UserContext);
  const [showDiagnosesDropdown, setShowDiagnosesDropdown] = useState(false);
  const [showChargesDropdown, setShowChargesDropdown] = useState(false);
  const [diagnosesApplied, setDiagnosesApplied] = useState(false);
  const [chargesApplied, setChargesApplied] = useState(false);
  const [showCallDuration, setShowCallDuration] = useState(false);
  const [callRecordList, setCallRecordList] = useState<CallRecord[]>();
  const meetingContext = useContext<MeetingContextState>(MeetingModalContext);
  const [selectedDraft, setSelectedDraft] = useState<SelectedCallRecordDraft>();
  const [changedDraft, setChangedDraft] = useState(false);
  const [showAddEditCallRecords, setShowAddEditCallRecords] = useState("");
  const billingContext = useContext<BillingContextState>(BillingContext);
  const [patientLocation, setPatientLocation] = useState<string>("");
  const [providerLocation, setProviderLocation] = useState<string>("");
  const [chargesNotSelected, setChargesNotSelected] = useState("");
  const [diagnosesNotSelected, setDiagnosesNotSelected] = useState("");
  const [selectedCharge, setSelectedCharge] = useState<boolean>(false);
  const [selectedDiagnos, setSelectedDiagnos] = useState<boolean>(false);
  const [isDraftId, setIsDraftId] = useState<any>();
  const [dateChange, setDateChange] = useState<any>();
  const draftIdRef = useRef<any>();
  const isAddBillClicked = useRef<boolean>(false);
  const isFieldEmpty = useRef<boolean>(false);

  const { id: userId } = useParams();

  const {
    data: totalMonthlyCallTimeData,
    refetch: getTotalMonthlyCallTimeData,
    isRefetching: isRefetchLoadingTotalMonthlyCallTimeData,
    isLoading: isLoadingTotalMonthlyCallTimeData
  } = useQuery(
    "total-monthly-call-time",
    async () => {
      return await createApiClient().getTotalMonthlyCallTime(
        patientContext.patientData?.id
          ? String(patientContext.patientData?.id)
          : "",
        userContext.userData?.internal_id
          ? userContext.userData?.internal_id
          : "",
        moment(form.getFieldValue("Date")).format("YYYY"),
        moment(form.getFieldValue("Date")).format("MM"),
        true
      );
    },
    {
      onSuccess: () => {
        setShowCallDuration(true);
      },
      enabled: false,
      cacheTime: 0,
      staleTime: 0
    }
  );

  const {
    isLoading: isLoadingChargesList,
    data: chargesData,
    error: isChargesListError
  } = useQuery(
    "charges-list",
    async () => {
      return await createApiClient().getChargesList(true);
    },
    {
      staleTime: 600000
    }
  );

  const {
    isLoading: isLoadingDiagnosesList,
    data: diagnosesData,
    error: isDiagnosesListError
  } = useQuery(
    "diagnoses-list",
    async () => {
      return await createApiClient().getDiagnosesList(true);
    },
    {
      staleTime: 600000
    }
  );

  const { data: callRecordNotesData, isLoading: isLoadingCallRecordNotes } =
    useQuery("call-record-notes", async () => {
      return await createApiClient().getCallRecordNotes();
    });

  const renderCharges = !isLoadingChargesList && !isChargesListError;
  const renderDiagnoses = !isLoadingDiagnosesList && !isDiagnosesListError;

  useEffect(() => {
    const latestApprovedDiagnosesItems =
      latestApprovedBill?.diagnoses &&
      latestApprovedBill?.diagnoses?.map(item => item.code);
    const latestApprovedChargesItems =
      latestApprovedBill?.charges &&
      latestApprovedBill?.charges?.map(item => item.code);

    if (renderDiagnoses) {
      const diagnosesList = diagnosesData?.map(
        (item: CheckboxMenuCommonInterface) => {
          if (latestApprovedDiagnosesItems?.includes(item.code)) {
            item.checked = true;
          } else {
            item.checked = false;
          }
          return item;
        }
      );
      if (diagnosesList) {
        billingContext.setDiagnosesMenuItems(diagnosesList);
      }
    }

    if (renderCharges) {
      const chargesList = chargesData?.map(
        (item: CheckboxMenuCommonInterface) => {
          if (latestApprovedChargesItems?.includes(item.code)) {
            item.checked = true;
          } else {
            item.checked = false;
          }
          return item;
        }
      );

      if (chargesList) {
        billingContext.setChargesMenuItems(chargesList);
      }
    }
    getTotalMonthlyCallTimeData();
  }, []);

  const selectedDiagnoses = billingContext.diagnosesMenuItems.filter(
    (obj: CheckboxMenuCommonInterface) => obj.checked
  );

  const selectedCharges = billingContext.chargesMenuItems.filter(
    (obj: CheckboxMenuCommonInterface) => obj.checked
  );

  useEffect(() => {
    if (selectedCharges.length > 0) {
      setChargesNotSelected("");
      setSelectedCharge(false);
    } else {
      setSelectedCharge(true);
    }
    if (selectedDiagnoses.length > 0) {
      setDiagnosesNotSelected("");
      setSelectedDiagnos(false);
    } else {
      setSelectedDiagnos(true);
    }
  }, [selectedCharges, selectedDiagnoses]);

  const {
    isLoading: isLoadingAddBillMutation,
    mutate: addBillMutation,
    data: addBillData
  } = useMutation<string>(
    "add-bill",
    async () => {
      return await createApiClient().addBill({
        status: "Draft",
        date_of_service: moment(form.getFieldValue("Date")).toISOString(),
        billing_diagnose_code: JSON.stringify(selectedDiagnoses),
        provider_name:
          get(userContext, "userData.firstName", "") +
          " " +
          get(userContext, "userData.lastName", ""),
        billing_charge_code: JSON.stringify(selectedCharges),
        patient_internal_id: patientContext.patientData?.id
          ? String(patientContext.patientData?.id)
          : "",
        provider_internal_id: userContext.userData?.internal_id
          ? userContext.userData?.internal_id
          : "",
        patient_location: form.getFieldValue("Patient Location"),
        total_duration_billed: totalMonthlyCallTimeData?.currentMonthTotal
          ? totalMonthlyCallTimeData?.currentMonthTotal
          : "0",
        provider_location: form.getFieldValue("Provider Location")
      });
    },
    {
      onSuccess: (res: any) => {
        getBillingList();
        message.success("Draft Added Successfully!");
        clearForm();
        // setShowCallDuration(false);
        setShowAddBill(false);
        setIsDraftId(res.id);
      },
      onError: () => {
        message.error("There was a problem adding this draft");
      }
    }
  );

  const { isLoading: isLoadingEditBillMutation, mutate: editBillMutation } =
    useMutation<string>(
      "add-bill",
      async () => {
        return await createApiClient().editBill(isDraftId, {
          status: "Draft",
          date_of_service: moment(form.getFieldValue("Date")).toISOString(),
          billing_diagnose_code: JSON.stringify(selectedDiagnoses),
          provider_name:
            get(userContext, "userData.firstName", "") +
            " " +
            get(userContext, "userData.lastName", ""),
          billing_charge_code: JSON.stringify(selectedCharges),
          patient_internal_id: patientContext.patientData?.id
            ? String(patientContext.patientData?.id)
            : "",
          provider_internal_id: get(userContext, "userData.internal_id", ""),
          patient_location: form.getFieldValue("Patient Location"),
          total_duration_billed: totalMonthlyCallTimeData?.currentMonthTotal
            ? totalMonthlyCallTimeData?.currentMonthTotal
            : "0",
          provider_location: form.getFieldValue("Provider Location")
        });
      },
      {
        onSuccess: () => {
          getBillingList();
          message.success("Draft Updated Successfully!");
        },
        onError: () => {
          message.error("There was a problem editing this draft");
        }
      }
    );

  const {
    isLoading,
    error,
    refetch: getCallRecords,
    isFetching: isFetchingCallRecords
  } = useQuery<CallRecord[]>(
    "call-records",
    async () => {
      return await createApiClient().getCallRecords(userId ? userId : "", true);
    },
    {
      onSuccess: successData => {
        const tempData: {
          id: number;
          desc: string;
          notes: string;
          call_duration: number;
          prv_name: string;
          date_p: string;
          call_type: string;
          status: string;
          provider_internal_id: number | string;
        }[] = [...successData];

        tempData.map(item => {
          item.date_p = moment(moment.utc(item.date_p).toDate()).format(
            "MMMM DD, YYYY hh:mm A"
          );
          item.call_duration = customRound(item.call_duration);
          return item;
        });
        setCallRecordList(tempData);
        if (meetingContext.callRecordId) {
          meetingContext.setSessionStatus(sessionStatusValues.NOTSTARTED);
          const previouslySelectedDraft = tempData?.find(
            item => String(item.id) === meetingContext.callRecordId
          );
          const tempDraft = {
            id: previouslySelectedDraft?.id || 0,
            date: previouslySelectedDraft?.date_p || "",
            itemsDiscussed: previouslySelectedDraft?.desc || "",
            duration: previouslySelectedDraft?.call_duration || 0,
            caller: "",
            other: previouslySelectedDraft?.notes || "",
            callType: previouslySelectedDraft?.call_type || ""
          };
          meetingContext.setCallRecordId("");
          setSelectedDraft(tempDraft);
        }
      }
    }
  );

  const [updateSelectedDate, setUpdateSelectedDate] = useState<any>();

  const todaysDate = new Date();

  let selectedMonth: any;
  let selectedYear: any;

  useEffect(() => {
    selectedMonth = moment(todaysDate).format("MM");
    selectedYear = moment(todaysDate).format("YYYY");
  }, []);
  useEffect(() => {
    selectedMonth = moment(updateSelectedDate?.date?._d).format("MM");
    selectedYear = moment(updateSelectedDate?.date?._d).format("YYYY");
    console.log("use", selectedMonth);
    getDetails99454();
    console.log("Details99454", Details99454);
  }, [updateSelectedDate]);
  // console.log("change", selectedMonth);

  const onDateChange = (value: Moment | null) => {
    setDateChange(value);
    form.setFieldsValue({ date: value });

    setUpdateSelectedDate({ date: value });

    if (form.isFieldTouched("Date") && form.getFieldValue("Date")) {
      getTotalMonthlyCallTimeData();
    }
  };

  const onPatientLocationChange = (value: string) => {
    setPatientLocation(value);
    form.setFieldsValue({ patientLocation: value });
  };

  const onProviderLocationChange = (value: string) => {
    setProviderLocation(value);
    form.setFieldsValue({ prodiverLocation: value });
  };

  // const onTextAreaChange = (value: string) => {
  //   form.setFieldsValue({ notesToBiller: value });
  // };

  const onFinish = () => {
    addBillMutation();
  };

  useEffect(() => {
    draftIdRef.current = isDraftId;
  }, [isDraftId]);

  useEffect(() => {
    isFieldEmpty.current =
      patientLocation !== "" &&
      providerLocation !== "" &&
      selectedDiagnos === false &&
      selectedCharge === false;
  }, [patientLocation, providerLocation, selectedDiagnos, selectedCharge]);

  useEffect(() => {
    return () => {
      if (isFieldEmpty.current === false) return;
      if (isAddBillClicked.current === true) return;
      if (draftIdRef.current) return;
      const confirmation = window.confirm(
        "Do you want to save this bill as a draft?"
      );

      if (confirmation) {
        addBillMutation();
      }
    };
  }, []);

  // useEffect(() => {
  //   return () => {
  //     const diagnosesNotSelectedCheck = selectedDiagnoses.length === 0;
  //     const chargesNotSelectedCheck = selectedCharges.length === 0;

  //     if (
  //       !chargesNotSelectedCheck &&
  //       !diagnosesNotSelectedCheck &&
  //       patientLocation !== "" &&
  //       providerLocation !== ""
  //     ) {
  //       const confirmation = window.confirm(
  //         "Do you want to save bill record as draft."
  //       );

  //       if (confirmation) {
  //         isDraftId ? editBillMutation() : addBillMutation();
  //         console.log("addBillMutation called", addBillMutation);
  //       }
  //     }
  //   };
  // }, []);

  const clearForm = () => {
    form.setFieldsValue({ Date: null });
    form.setFieldsValue({ "Patient Location": null });
    form.setFieldsValue({ "Provider Location": null });
    // form.setFieldsValue({ "Notes to Biller": null });

    let diagnosesTemp = [...billingContext.diagnosesMenuItems];
    let chargesTemp = [...billingContext.chargesMenuItems];

    diagnosesTemp = diagnosesTemp.map(item => {
      item.checked = false;
      return item;
    });

    chargesTemp = chargesTemp.map(item => {
      item.checked = false;
      return item;
    });

    billingContext.setDiagnosesMenuItems(diagnosesTemp);

    billingContext.setChargesMenuItems(chargesTemp);
  };

  const onRepopulate = () => {
    form.resetFields();
    form.setFieldsValue({
      "Date of Service": moment()
    });
    getTotalMonthlyCallTimeData();

    if (latestApprovedBill) {
      const initialDiagnosesMenuItems = billingContext.diagnosesMenuItems.map(
        (obj: CheckboxMenuCommonInterface) => {
          let found = false;
          for (let i = 0; i < latestApprovedBill.diagnoses.length; i++) {
            if (
              get(latestApprovedBill, `diagnoses[${i}].code`, "") === obj.code
            ) {
              found = true;
              break;
            }
          }
          if (found) {
            obj.checked = true;
          } else {
            obj.checked = false;
          }
          return obj;
        }
      );

      const initialChargesMenuItems = billingContext.chargesMenuItems.map(
        (obj: CheckboxMenuCommonInterface) => {
          let found = false;
          for (let i = 0; i < latestApprovedBill.charges.length; i++) {
            if (
              get(latestApprovedBill, `charges[${i}].code`, "") === obj.code
            ) {
              found = true;
              break;
            }
          }
          if (found) {
            obj.checked = true;
          } else {
            obj.checked = false;
          }
          return obj;
        }
      );

      billingContext.setDiagnosesMenuItems(initialDiagnosesMenuItems);

      billingContext.setChargesMenuItems(initialChargesMenuItems);
    }
  };

  const locationOptions: { label: string; value: string }[] = [
    {
      label: "Home",
      value: "Home"
    },
    {
      label: "Office",
      value: "Office"
    }
  ];

  const minutes = Number(totalMonthlyCallTimeData?.currentMonthTotal) / 60;

  const [billableModal, setBillableModal] = useState<boolean>(false);

  const handleBillableToggle = () => {
    setBillableModal(!billableModal);
  };

  const items: MenuProps["items"] = [
    {
      key: 1,
      label: <span onClick={onRepopulate}>Repopulate</span>
    },
    {
      key: 2,
      label: <div onClick={handleBillableToggle}>Billable Details</div>
    }
  ];

  const {
    isLoading: Details99454Loading,
    mutate: getDetails99454,
    data: Details99454
  } = useMutation("getDetails99454", () => {
    return createApiClient().getDetails99454(
      userId,
      selectedMonth,
      selectedYear
    );
  });

  const formattedTime = (minutes: any) => {
    const fullMinutes = Math.floor(minutes);
    const seconds = Math.round((minutes - fullMinutes) * 60);
    const formattedSeconds = seconds.toString().padStart(2, "0");
    return `${fullMinutes}:${formattedSeconds}`;
  };

  return (
    <ViewBillContainer>
      <ViewBillHeader>
        <Row>
          <Col span={12}>Add Bill</Col>
          <Col span={12} style={{ textAlign: "right", paddingRight: "20px" }}>
            <CloseOutlined
              onClick={() => {
                isAddBillClicked.current = true;
                setShowAddBill(false);
              }}
            />
          </Col>
        </Row>
      </ViewBillHeader>
      <Form
        name="call records"
        form={form}
        onFinish={() => {
          const diagnosesNotSelectedCheck = selectedDiagnoses.length === 0;
          const chargesNotSelectedCheck = selectedCharges.length === 0;

          if (!chargesNotSelectedCheck && !diagnosesNotSelectedCheck) {
            onFinish();
          }
        }}
      >
        <>
          <ViewBillBody>
            <Row gutter={[20, 0]}>
              <Col span={12}>
                <Form.Item
                  name="Date"
                  label=""
                  rules={[{ required: true }]}
                  initialValue={moment()}
                >
                  <AppDatePicker
                    label="Date of Service"
                    value={null}
                    customDateFormat="MMM DD, YYYY"
                    onChange={onDateChange}
                    disabledDate={function disabledDate(current) {
                      return (
                        (current && current < moment().subtract(6, "months")) ||
                        current > moment().endOf("day")
                      );
                    }}
                  />
                </Form.Item>
              </Col>
              <Col span={12} style={{ textAlign: "right" }}>
                {isLoadingTotalMonthlyCallTimeData ||
                isRefetchLoadingTotalMonthlyCallTimeData ? (
                  <Spin style={{ marginTop: "10px" }} />
                ) : (
                  <>
                    {!totalMonthlyCallTimeData ? null : (
                      <>
                        {showCallDuration && (
                          <>
                            <div style={{ fontSize: "12px" }}>
                              Call Duration(Monthly)
                            </div>
                            <div style={{ fontSize: "12px" }}>
                              <span
                                style={{ fontFamily: "Century Gothic Bold" }}
                              >
                                {formattedTime(minutes)}
                              </span>{" "}
                              minutes
                            </div>
                          </>
                        )}
                      </>
                    )}
                  </>
                )}
              </Col>
              {/* <Col
                span={12}
                style={{
                  marginBottom: 20,
                  fontSize: "12px",
                  display: "flex",
                  justifyContent: "left",
                  flexDirection: "column"
                }}
              >
                <div>Days of Recording</div>
                <div>
                  {Details99454Loading ? (
                    <Spin style={{ marginTop: "10px" }} />
                  ) : (
                    <span style={{ fontWeight: "bold" }}>
                      {Details99454?.days_recording?.length == 0
                        ? "-"
                        : Details99454?.days_recording[0]?.number_of_days}
                    </span>
                  )}
                </div>
              </Col> */}

              {/* <Col
                span={12}
                style={{
                  marginBottom: 20,
                  fontSize: "12px",
                  display: "flex",
                  justifyContent: "left",
                  flexDirection: "column"
                }}
              >
                <div>Last Date of Billing</div>
                {Details99454Loading ? (
                  <Spin style={{ marginTop: "10px" }} />
                ) : (
                  <span style={{ fontWeight: "bold" }}>
                    {!Details99454?.date_of_service_99454 ? (
                      "-"
                    ) : (
                      <>
                        {" "}
                        {moment(Details99454?.date_of_service_99454).format(
                          "MM-DD-YYYY"
                        )}
                      </>
                    )}
                  </span>
                )}
              </Col> */}
              <Col span={12}>
                <Form.Item
                  name="Patient Location"
                  label=""
                  rules={[{ required: true }]}
                >
                  <AppSelect
                    label="Patient Location"
                    value={""}
                    placeholder="Select Location"
                    onChange={onPatientLocationChange}
                    options={locationOptions}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="Provider Location"
                  label=""
                  rules={[{ required: true }]}
                >
                  <AppSelect
                    label="Provider Location"
                    value={""}
                    placeholder="Select Location"
                    onChange={onProviderLocationChange}
                    options={locationOptions}
                  />
                </Form.Item>
              </Col>

              {renderDiagnoses && (
                <Col span={24}>
                  <Row>
                    <ListTitleCol span={12}>Diagnoses</ListTitleCol>
                    <AddCol span={12}>
                      <BillDiagnosisCheckboxDropdown
                        setShowDropdown={setShowDiagnosesDropdown}
                        showDropdown={showDiagnosesDropdown}
                        diagnosesApplied={diagnosesApplied}
                        setDiagnosesApplied={setDiagnosesApplied}
                      />
                    </AddCol>
                  </Row>
                  <ListItemWrapper>
                    {selectedDiagnoses.map(
                      (obj: CheckboxMenuCommonInterface) => {
                        return (
                          <ListItemRowStyle key={obj.code}>
                            <Col span={20}>{obj.desc}</Col>
                            <Col
                              span={4}
                              style={{ textAlign: "right" }}
                              onClick={() => {
                                let tempItems = [
                                  ...billingContext.diagnosesMenuItems
                                ];
                                tempItems = tempItems.map(temp => {
                                  if (obj.code === temp.code) {
                                    temp.checked = false;
                                  }
                                  return temp;
                                });

                                billingContext.setDiagnosesMenuItems(tempItems);
                              }}
                            >
                              <CloseOutlined />
                            </Col>
                          </ListItemRowStyle>
                        );
                      }
                    )}
                  </ListItemWrapper>

                  <CommonErrorMessage message={diagnosesNotSelected} />
                </Col>
              )}
              {renderCharges && (
                <Col span={24}>
                  <Row>
                    <ListTitleCol span={12}>Charges</ListTitleCol>
                    <AddCol span={12}>
                      <BillChargesCheckboxDropdown
                        setShowDropdown={setShowChargesDropdown}
                        showDropdown={showChargesDropdown}
                        applied={chargesApplied}
                        setApplied={setChargesApplied}
                      />
                    </AddCol>
                  </Row>
                  <ListItemWrapper>
                    {selectedCharges.map((obj: CheckboxMenuCommonInterface) => {
                      return (
                        <ListItemRowStyle key={obj.code}>
                          <Col span={24}>{obj.code}</Col>
                          <Col span={20}>{obj.desc}</Col>
                          <Col
                            span={4}
                            style={{ textAlign: "right" }}
                            onClick={() => {
                              let tempItems = [
                                ...billingContext.chargesMenuItems
                              ];
                              tempItems = tempItems.map(temp => {
                                if (obj.code === temp.code) {
                                  temp.checked = false;
                                }
                                return temp;
                              });
                              billingContext.setChargesMenuItems(tempItems);
                            }}
                          >
                            <CloseOutlined />
                          </Col>
                        </ListItemRowStyle>
                      );
                    })}
                  </ListItemWrapper>
                  <CommonErrorMessage message={chargesNotSelected} />
                </Col>
              )}
            </Row>
            {/* <NotesToBillerWrapper>
              <Form.Item name="Notes to Biller" rules={[{ required: true }]}>
                <AppTextArea
                  value={""}
                  label="Notes to Biller"
                  placeholder="Enter Text"
                  onChange={onTextAreaChange}
                />
              </Form.Item>
            </NotesToBillerWrapper> */}
          </ViewBillBody>
          <ViewBillFooter>
            <AppButton
              type={ButtonType.Link}
              buttonContent="Clear"
              onClick={clearForm}
              style={{ color: Colors.LightGrey, float: "left", top: 5 }}
            />
            <Space size={10}>
              <Form.Item>
                {/* <Space>
                  <Space wrap>
                    <Dropdown menu={{ items }} placement="top">
                      <ToogleButton>⋮</ToogleButton>
                    </Dropdown>
                  </Space>
                </Space> */}
                <AppButton
                  type={ButtonType.Secondary}
                  buttonContent="Repopulate"
                  onClick={() => {
                    clearForm();
                    onRepopulate();
                  }}
                  disabled={isLoadingAddBillMutation}
                />
              </Form.Item>
              <Form.Item>
                <AppButton
                  type={ButtonType.Primary}
                  buttonContent="Save as Draft"
                  htmlType="submit"
                  disabled={!enableBilling}
                  onClick={() => {
                    isAddBillClicked.current = true;
                    const diagnosesNotSelectedCheck =
                      selectedDiagnoses.length === 0;
                    const chargesNotSelectedCheck =
                      selectedCharges.length === 0;

                    if (chargesNotSelectedCheck) {
                      setChargesNotSelected("Please select Charge(s)");
                    }
                    if (diagnosesNotSelectedCheck) {
                      setDiagnosesNotSelected("Please Select Diagnosis(es)");
                    }
                  }}
                  loading={isLoadingAddBillMutation}
                />
              </Form.Item>
            </Space>
          </ViewBillFooter>
        </>
      </Form>
      <AppModal
        title="Billable Details"
        visible={billableModal}
        width="65%"
        footer={false}
        onCancel={handleBillableToggle}
      >
        <Tabs defaultActiveKey="1" size="small" type="card">
          <Tabs.TabPane tab="Call Records" key="1">
            <CallRecordList
              setChangedDraft={setChangedDraft}
              data={callRecordList ? callRecordList : null}
              isLoading={
                isLoading ||
                isFetchingCallRecords ||
                isLoadingCallRecordNotes ||
                !userContext.userData
              }
              errorMessage={
                error
                  ? "There was an error in fetching the call record list"
                  : ""
              }
              setSelectedDraft={setSelectedDraft}
              setShowAddEditCallRecords={setShowAddEditCallRecords}
              selectedDraft={selectedDraft ? selectedDraft : null}
            />
          </Tabs.TabPane>
          <Tabs.TabPane tab="Talk Time" key="2">
            Content of Tab Pane 2
          </Tabs.TabPane>
        </Tabs>
      </AppModal>
    </ViewBillContainer>
  );
};

// const NotesToBillerWrapper = styled.div`
//   margin-top: 10px;
// `;

const ListItemWrapper = styled.div`
  .ant-row {
    border-bottom: 1px solid ${Colors.Lavender};

    :last-child {
      border-bottom: none;
    }
  }
`;

const ListItemRowStyle = styled(Row)`
  font-size: 12px;
  padding: 8px;
`;

const AddCol = styled(Col)`
  text-align: right;
  color: ${Colors.DodgerBlue};
  font-family: Century Gothic Bold;
  svg {
    margin-bottom: -7px;
    margin-right: 5px;
  }
`;

const ListTitleCol = styled(Col)`
  font-family: Century Gothic Bold;
  font-size: 12px;
`;

const ViewBillHeader = styled.div`
  height: 60px;
  padding-left: 25px;
  padding-top: 18px;
  font-family: Century Gothic Bold;
  font-size: 14px;
  border-bottom: 1px solid ${Colors.Lavender};
`;

const ViewBillBody = styled.div`
  height: 50px;
  padding: 20px;
  padding-top: 25px;
  border-bottom: 1px solid ${Colors.Lavender};
  height: calc(70vh - 130px);
  overflow: scroll;

  .notes-to-biller-title {
    margin-top: -10px;
  }
`;

const ViewBillFooter = styled.div`
  height: 70px;
  padding: 14px;
  text-align: right;
`;

const ViewBillContainer = styled.div`
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.05), 0 0px 10px rgba(0, 0, 0, 0.08);
  border-radius: 0px 0px 8px 8px;
  margin-top: -2px;
  background-color: ${Colors.White};
  max-height: 70vh;
  font-size: 12px;
`;

const ToogleButton = styled.div`
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.05), 0 0px 10px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  width: 40px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: ${Colors.White};
  text-size: 40px;
`;
