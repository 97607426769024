import { FormInstance } from "antd";
import React, { useEffect, useState, useRef } from "react";
import { AppTextArea } from "components/inputs";
import { SelectedCallRecordDraft } from "pages/callRecords/callRecords";

export interface CallRecordOtherIssuesProps {
  readonly otherIssues?: string;
}

interface AddCallRecordFormProps {
  readonly value?: CallRecordOtherIssuesProps;
  readonly onChange?: (value: CallRecordOtherIssuesProps) => void;
  readonly clearForm: boolean;
  readonly selectedDraft?: SelectedCallRecordDraft;
  readonly form?: FormInstance;
  readonly disabled?: boolean;
  readonly handleMouseLeave?: (
    e: React.MouseEvent<HTMLTextAreaElement, MouseEvent>
  ) => void;
  readonly enableTypingTimer?: boolean;
}

export const CallRecordOtherIssues: React.FC<AddCallRecordFormProps> = ({
  value,
  onChange,
  clearForm,
  selectedDraft,
  form,
  disabled,
  handleMouseLeave,
  enableTypingTimer
}) => {
  const [otherIssues, setOtherIssues] = useState<string>("");
  const typingStartTime = useRef<number | null>(null);
  const typingTimer = useRef<NodeJS.Timeout | null>(null);
  const [typedTimerValue, setTypedTimerValue] = useState<number>(0);
  const [isTyping, setIsTyping] = useState(false);

  const triggerChange = (changedValue: CallRecordOtherIssuesProps) => {
    onChange?.({
      otherIssues,
      ...value,
      ...changedValue
    });
  };

  const onOtherIssuesChange = (newOtherIssues: string) => {
    setOtherIssues(newOtherIssues);

    // Start tracking typing time
    if (!typingStartTime.current) {
      typingStartTime.current = Date.now();
      setIsTyping(true);
    }

    // Reset the inactivity timer
    if (typingTimer.current) {
      clearTimeout(typingTimer.current);
    }

    // Log typing duration if user stops for 2 seconds
    typingTimer.current = setTimeout(() => {
      if (typingStartTime.current) {
        setIsTyping(false);
        const typingDuration = (Date.now() - typingStartTime.current) / 1000;
        console.log(`User typed for ${typingDuration.toFixed(0)} seconds.`);
        setTypedTimerValue(prev => prev + typingDuration);
        typingStartTime.current = null; // Reset the timer
      }
    }, 2500);

    if (form) {
      form.setFieldsValue({
        otherIssues: { otherIssues: newOtherIssues }
      });
    }
    triggerChange({ otherIssues: newOtherIssues });
  };

  useEffect(() => {
    if (selectedDraft) {
      setOtherIssues(String(selectedDraft.other));
      if (form) {
        form.setFieldsValue({
          otherIssues: { otherIssues: selectedDraft.other }
        });
      }
    }
  }, [selectedDraft]);

  useEffect(() => {
    if (clearForm) {
      setOtherIssues("");
      if (form) {
        form.setFieldsValue({
          otherIssues: { otherIssues: "" }
        });
      }
    }
  }, [clearForm]);

  return (
    <AppTextArea
      value={otherIssues}
      label="Other Issues"
      onChange={onOtherIssuesChange}
      placeholder="Enter Text"
      disabled={disabled}
      handleMouseLeave={handleMouseLeave}
      isTyping={isTyping}
      typedTimerValue={typedTimerValue}
      enableTypingTimer={enableTypingTimer}
    />
  );
};
