import React, { useContext, useEffect, useState } from "react";
import Skeleton from "antd/lib/skeleton";
import { AppCard } from "components/card";
import { CommonPatientExtensionItems } from "pages/patients/commonPatientExtensionItems";
import {
  CallLogsTitleText,
  EmptyDataText,
  ErrorMessage,
  OverflowCallLogsCardContainer
} from "./styles";
import { useQuery } from "react-query";
import { createApiClient } from "apiClient";
import {
  PatientContextState,
  PatientContext
} from "contextApis/patientContext";
import moment from "moment";
import { UserContext, UserContextState } from "contextApis/userContext";
import { CommonErrorMessage } from "components/CommonErrorMessage";
import { CallLogsTable } from "./callLogsTable";

interface TotalDurationData {
  readonly totalDurationLabel: string;
  readonly totalDurationTime: string;
}

export interface CallLogsList {
  readonly id?: string;
  readonly call_duration: number;
  readonly prv_name: string | TotalDurationData;
  readonly date_p: string;
  readonly call_type: string;
}

export const CallLogsPage: React.FC = () => {
  const patientContext = useContext<PatientContextState>(PatientContext);
  const userContext = useContext<UserContextState>(UserContext);
  const [callLogs, setCallLogs] = useState<CallLogsList[]>([]);
  const {
    isLoading,
    data,
    error,
    // refetch: getCallRecords,
    isFetching
  } = useQuery<CallLogsList[]>(
    "call-logs",
    async () => {
      return await createApiClient().getCallLogs(
        patientContext.patientData?.id || "",
        true
      );
    },
    {
      enabled: patientContext.patientData?.id ? true : false
    }
  );

  useEffect(() => {
    if (data) {
      const callLogsListwithTotalTimes: CallLogsList[] = data.reduce(
        (acc: CallLogsList[], log, index) => {
          if (index === 0) return acc.concat({ id: index.toString(), ...log });
          const prevLog = acc[acc.length - 1];

          const prevLogDate = moment(
            prevLog.date_p,
            "MM-DD-YYYY hh:mm A"
          ).format("MMMM YYYY");
          const currentLogDate = moment(
            log.date_p,
            "MM-DD-YYYY hh:mm A"
          ).format("MMMM YYYY");

          if (currentLogDate === prevLogDate && index === data.length - 1) {
            const totalCallDuration = [...acc, log].reduce((acc, logItem) => {
              if (
                moment(logItem.date_p, '"MM-DD-YYYY hh:mm A').format(
                  "MMMM YYYY"
                ) === prevLogDate
              ) {
                return acc + logItem.call_duration;
              }
              return 0;
            }, 0);
            return acc.concat([
              { id: index.toString(), ...log },
              {
                id: `${index}_totalDuration`,
                date_p: ``,
                prv_name: {
                  totalDurationTime: String(totalCallDuration),
                  totalDurationLabel: prevLogDate.split(/\s+/)[0]
                },
                call_duration: 0,
                call_type: ""
              }
            ]);
          }

          if (currentLogDate === prevLogDate)
            return acc.concat({ id: index.toString(), ...log });

          const totalCallDuration = acc.reduce((acc, log) => {
            if (
              moment(log.date_p, '"MM-DD-YYYY hh:mm A').format("MMMM YYYY") ===
              prevLogDate
            ) {
              return log.call_duration + acc;
            }
            return 0;
          }, 0);

          return acc.concat([
            {
              id: `${index}_totalDuration`,
              date_p: ``,
              prv_name: {
                totalDurationTime: `${
                  totalCallDuration % 1 !== 0
                    ? totalCallDuration.toFixed(2)
                    : totalCallDuration.toString()
                }`,
                totalDurationLabel: prevLogDate.split(/\s+/)[0]
              },
              call_duration: 0,
              call_type: ""
            },
            { id: index.toString(), ...log }
          ]);
        },
        []
      );
      setCallLogs(callLogsListwithTotalTimes);
    }
  }, [data]);

  const isRemoteMonitoringEnabled =
    userContext.userData?.remote_monitoring.toLowerCase().trim() === "y";

  return (
    <>
      <CommonPatientExtensionItems />
      <CallLogsTitleText>Call History</CallLogsTitleText>
      <AppCard cardHeight="auto" cardWidth="100%">
        {isRemoteMonitoringEnabled ? (
          <OverflowCallLogsCardContainer>
            {isLoading || isFetching || !userContext.userData ? (
              <Skeleton
                loading={isLoading || isFetching}
                active={isLoading || isFetching}
                avatar={false}
                paragraph={{ rows: 9 }}
              />
            ) : error ? (
              <ErrorMessage>
                There was an error in fetching call logs
              </ErrorMessage>
            ) : callLogs.length === 0 ? (
              <EmptyDataText>No Data</EmptyDataText>
            ) : (
              <CallLogsTable data={callLogs} />
            )}
          </OverflowCallLogsCardContainer>
        ) : (
          <CommonErrorMessage message="You do not have remote monitoring permissions, you are not authorized to access call logs." />
        )}
      </AppCard>
    </>
  );
};
