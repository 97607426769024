import Table, { ColumnsType } from "antd/lib/table";
import React from "react";
import { Colors } from "helpers/colors";
import moment from "moment";
import { CallLogsList } from "./callLogs";

export interface CallLogsTableInterface {
  readonly data: CallLogsList[];
}

interface TotalDurationData {
  readonly totalDurationLabel: string;
  readonly totalDurationTime: string;
}

const columns: ColumnsType<CallLogsList> = [
  {
    title: "Date",
    key: "date_p",
    dataIndex: "date_p",
    onCell: (data): React.HTMLAttributes<any> => {
      return {
        style:
          typeof data.prv_name !== "string"
            ? {
                backgroundColor: Colors.BabyBlue,
                borderRadius: "0 8px 8px 0"
              }
            : {}
      };
    },
    render: (value: string, row: CallLogsList) => {
      if (typeof row.prv_name === "string") {
        return moment(moment.utc(value, "MM-DD-YYYY hh:mm A").toDate()).format(
          "MMM DD YYYY | hh:mm A"
        );
      }
      return "";
    }
  },
  {
    title: "Provider",
    key: "prv_name",
    dataIndex: "prv_name",
    onCell: (data): React.HTMLAttributes<any> => {
      return {
        style:
          typeof data.prv_name !== "string"
            ? {
                backgroundColor: Colors.BabyBlue
              }
            : {}
      };
    },
    render: (value: string | TotalDurationData) => {
      if (typeof value === "string") {
        return value;
      }
      const totalDurationTime = Number(value?.totalDurationTime) || 0;
      return (
        <div>
          <b style={{ marginRight: 5 }}>{value.totalDurationLabel}</b>
          <span>Total Call Time: </span>
          <span>
            {Math.floor(totalDurationTime / 60)}:
            {(totalDurationTime % 60).toFixed(0).padStart(2, "0")} minutes
          </span>
        </div>
      );
    }
  },
  {
    title: "Call Duration",
    key: "call_duration",
    dataIndex: "call_duration",
    onCell: (data): React.HTMLAttributes<any> => {
      return {
        style:
          typeof data.prv_name !== "string"
            ? {
                backgroundColor: Colors.BabyBlue,
                borderRadius: "0 8px 8px 0"
              }
            : {}
      };
    },
    render: (value: number, row: CallLogsList) => {
      if (typeof row.prv_name === "string") {
        const minutes = value / 60;
        const fullMinutes = Math.floor(minutes);
        const seconds = Math.round((minutes - fullMinutes) * 60);
        const formattedSeconds = seconds.toString().padStart(2, "0");
        return seconds > 0
          ? `${fullMinutes}:${formattedSeconds} minutes`
          : `${fullMinutes} minutes`;
      }
      return "";
    }
  }
];

export const CallLogsTable: React.FC<CallLogsTableInterface> = ({ data }) => {
  return (
    <Table<CallLogsList>
      dataSource={data}
      columns={columns}
      pagination={false}
      size="small"
      rowKey="id"
      data-testid="call-logs-table"
    />
  );
};
