import React, { useState, useEffect, useRef, useContext } from "react";
import ReactDOM from "react-dom";
import {
  MeetingContextState,
  MeetingModalContext,
  sessionStatusValues
} from "contextApis/meetingModalContext";
import Draggable from "react-draggable";
import styled from "styled-components";
import { Button, Card, message, Spin } from "antd";
import Form from "antd/lib/form";
import {
  CloseOutlined,
  FullscreenOutlined,
  InfoCircleOutlined,
  MinusOutlined
} from "@ant-design/icons";
import {
  PatientContext,
  PatientContextState
} from "contextApis/patientContext";
import { UserContext, UserContextState } from "contextApis/userContext";
import { useMutation, useQuery } from "react-query";
import { createApiClient } from "apiClient";
import { CallRecordDuration } from "pages/callRecords/formInputs/CallRecordDuration";
import { CallTypes } from "pages/callRecords/callRecords";
import { CallRecordDate } from "pages/callRecords/formInputs/CallRecordDate";
import { CallRecordDiscussionItems } from "pages/callRecords/formInputs/CallRecordDiscussionItems";
import { CallRecordOtherIssues } from "pages/callRecords/formInputs/CallRecordOtherIssues";
import { AppButton } from "components/button";
import { ButtonType } from "components/button/appButton";
import { AppModal } from "components/modal";
import { CallRecordBody } from "sharedTypes";
import moment from "moment";
import { useLocation } from "react-router";

interface PutCallRecordProps {
  patientId: string;
  callId: string | number;
  body: CallRecordBody;
}

const FinalizeCallModalElement: HTMLElement | null =
  document.getElementById("modal-dom");

const FinalizeCallModal: React.FC = () => {
  if (!FinalizeCallModalElement) {
    return null;
  }
  const [form] = Form.useForm();
  const meetingContext = useContext<MeetingContextState>(MeetingModalContext);
  const patientContext = useContext<PatientContextState>(PatientContext);
  const userContext = useContext<UserContextState>(UserContext);
  const [selectedDraft, setSelectedDraft] = useState<any>();
  const [isMinimized, setIsMinimized] = useState(false);
  const [timer, setTimer] = useState(0);
  const [borderRed, setBorderRed] = useState(false);
  const intervalId = useRef<NodeJS.Timeout | null>(null);
  const inactivityTimeout = useRef<NodeJS.Timeout | null>(null);
  const [submissionData, setSubmissionData] = useState<any>();
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [status, setStatus] = useState<string>("COMPLETED");
  const [userCalled, setUserCalled] = useState<boolean>(false);

  const callFinalizingPopUp = () => {
    meetingContext.setShowUpdateCallDetailsPopup(true);
    meetingContext.setSessionStatus(sessionStatusValues.NOTSTARTED);
  };

  const path = useLocation();

  const formattedTime = (minutes: any) => {
    const fullMinutes = Math.floor(minutes);
    const seconds = Math.round((minutes - fullMinutes) * 60);
    const formattedSeconds = seconds.toString().padStart(2, "0");
    return `${fullMinutes}:${formattedSeconds}`;
  };

  /* ↓↓ API CALLS ↓↓ */

  const {
    isFetching: isFetchingCallRecords,
    refetch: getCallRecords,
    data: getCallRecordsData
  } = useQuery<any>("call-records", async () => {
    const response = await createApiClient().getCallRecords(
      patientContext.patientData?.internal_id
        ? patientContext.patientData.internal_id
        : "",
      true
    );

    const latestCallRecord: any = response?.map(x => {
      return {
        id: x.id,
        date: x.date_p,
        itemsDiscussed: x.notes,
        duration: x.call_duration,
        caller: "",
        other: x.desc,
        callType: x.call_type
      };
    })[0];

    if (meetingContext.sessionStatus === sessionStatusValues.ENDED) {
      latestCallRecord?.duration < 59
        ? !userContext.isProviderModal &&
          meetingContext.setShowAddCallConfirmationModal(true)
        : !userContext.isProviderModal && callFinalizingPopUp();
    }
    return response;
  });

  const { data: callRecordNotesData, isLoading: isLoadingCallRecordNotes } =
    useQuery("call-record-notes", async () => {
      return await createApiClient().getCallRecordNotes();
    });

  useEffect(() => {
    // console.log("getCallRecordsData====>", getCallRecordsData);
    if (getCallRecordsData) {
      const latestCallRecord: any = getCallRecordsData?.map((x: any) => {
        return {
          id: x.id,
          date: x.date_p,
          itemsDiscussed: x.notes,
          duration: x.call_duration,
          caller: "",
          other: x.desc,
          callType: x.call_type
        };
      })[0];

      setSelectedDraft(latestCallRecord);
    }
  }, [getCallRecordsData]);

  useEffect(() => {
    form.setFieldsValue({ id: selectedDraft?.id });
  }, [selectedDraft]);

  useEffect(() => {
    if (
      meetingContext.fetchCallRecords &&
      meetingContext.sessionStatus === sessionStatusValues.ENDED
    ) {
      setTimeout(() => {
        getCallRecords();
      }, 500);
    }
  }, [
    meetingContext.fetchCallRecords,
    getCallRecordsData,
    meetingContext.sessionStatus
  ]);

  /* ↓↓ UTILITY FUNCTION ↓↓ */

  const formatTime = (seconds: number) => {
    const minutes = Math.floor(seconds / 60)
      .toString()
      .padStart(2, "0");
    const secs = (seconds % 60).toString().padStart(2, "0");
    return `${minutes}:${secs}`;
  };

  function convertToSeconds(timeStr: any) {
    if (!timeStr?.includes(":")) {
      const minutes = Number(timeStr);
      return minutes * 60;
    }
    const [minutes, seconds] = timeStr.split(":").map(Number);
    return minutes * 60 + (seconds + timer);
  }

  const startTimer = () => {
    if (intervalId.current) return;
    intervalId.current = setInterval(() => {
      setTimer(prev => prev + 1);
    }, 1000);
  };

  const stopTimer = () => {
    if (intervalId.current) {
      clearInterval(intervalId.current);
      intervalId.current = null;
    }
  };

  const resetInactivityTimer = () => {
    if (inactivityTimeout.current) clearTimeout(inactivityTimeout.current);

    inactivityTimeout.current = setTimeout(() => {
      setBorderRed(true); // Show red border if inactive
      stopTimer(); // Stop counting when inactive
    }, 5000); // 5 seconds inactivity
  };

  const handleUserActivity = () => {
    setBorderRed(false); // Remove red border
    startTimer(); // Ensure the timer resumes
    resetInactivityTimer(); // Restart inactivity timer
  };

  useEffect(() => {
    document.addEventListener("mousemove", handleUserActivity);
    document.addEventListener("keydown", handleUserActivity);
    document.addEventListener("click", handleUserActivity);

    return () => {
      document.removeEventListener("mousemove", handleUserActivity);
      document.removeEventListener("keydown", handleUserActivity);
      document.removeEventListener("click", handleUserActivity);
    };
  }, []);

  const handleSubmit = () => {
    if (form?.getFieldValue("date")?.date === undefined) return;
    const noDate = !form?.getFieldValue("date")?.date;
    const noDuration = !form?.getFieldValue("duration")?.duration || "";
    const noDiscussionItems =
      form?.getFieldValue("discussionItems")?.discussionItems <= 0 || "";
    const noOtherIssues =
      !form?.getFieldValue("otherIssues")?.otherIssues || "";

    if (noDate && noDuration && noDiscussionItems && noOtherIssues) {
      setErrorMessage("The form is empty, please enter a value to proceed.");
      return;
    }
    setErrorMessage("");

    setSubmissionData({
      id: form?.getFieldValue("id") || "",
      date: form?.getFieldValue("date")?.date || "",
      duration: form?.getFieldValue("duration").duration || "",
      discussionItems:
        form?.getFieldValue("discussionItems")?.discussionItems || "",
      otherIssues: form?.getFieldValue("otherIssues")?.otherIssues || ""
    });
  };

  const handleOnFinish = (values: any) => {
    if (!values) return;
    const callRecordBody: CallRecordBody = {
      callDateTime: moment.utc(values?.date).format("MM-DD-YYYY HH:mm"),
      callLength: convertToSeconds(values?.duration),
      notes: [
        ...values.discussionItems.map((item: any) => ({
          desc: item.note_detail,
          id: item.id.toString(),
          isSelected: "true"
        })),
        { desc: values.otherIssues, id: "999" }
      ],
      patient_id: patientContext.patientData?.id.toString() || "",
      provider_id: userContext.userData?.internal_id.toString() || "",
      typeOfCall: selectedDraft?.callType ? selectedDraft?.callType : "Manual",
      status: path.pathname === "/patients" ? "DRAFT" : "COMPLETED"
    };
    if (userCalled) {
      putCallRecord({
        patientId: patientContext.patientData?.id.toString() || "",
        callId: values.id || "",
        body: callRecordBody
      });
    }
  };

  useEffect(() => {
    if (path.pathname === "/patients") {
      // console.log("===path===", path.pathname);
      form.submit();
      handleSubmit();
    }
  }, [path.pathname]);

  useEffect(() => {
    // console.log("submissionData upper");
    if (submissionData) {
      // console.log("submissionData lower");
      if (path.pathname === "/patients") {
        handleOnFinish(submissionData);
        setSubmissionData(null);
      }
    }
  }, [submissionData]);

  useEffect(() => {
    if (meetingContext.showUpdateCallDetailsPopup === true) {
      setUserCalled(true);
    }
  }, [meetingContext.showUpdateCallDetailsPopup, userCalled]);

  useEffect(() => {
    // Clear any existing timers when component mounts or unmounts
    if (intervalId.current) {
      clearInterval(intervalId.current);
      intervalId.current = null;
    }

    // Only start/stop timer based on modal visibility
    if (meetingContext.showUpdateCallDetailsPopup) {
      setTimer(0); // Reset timer once when opening

      // Use a ref to track time internally without causing re-renders
      const startTime = Date.now();

      intervalId.current = setInterval(() => {
        // Update timer based on elapsed time since start
        const elapsed = Math.floor((Date.now() - startTime) / 1000);
        setTimer(elapsed);
      }, 1000);
    }

    // Cleanup function
    return () => {
      if (intervalId.current) {
        clearInterval(intervalId.current);
        intervalId.current = null;
      }
    };
  }, [meetingContext.showUpdateCallDetailsPopup]); // Only depend on modal visibility

  /* ↓↓ API CALLS ↓↓ */

  const { mutate: putCallRecord } = useMutation(
    "edit-call-record",
    async (props: PutCallRecordProps) => {
      /* eslint-disable */
      createApiClient().putCallRecord(
        props.patientId,
        props.callId,
        props.body
      );
      /* eslint-enable */
    },
    {
      onSuccess: () => {
        intervalId.current = null;
        setUserCalled(false);
        message.success("Call record details saved");
        setTimer(0);
        stopTimer();
        meetingContext.setShowUpdateCallDetailsPopup(false);
        setTimeout(() => {
          getCallRecords();
          meetingContext.setRefreshCallRecordsStatus(true);
        }, 500);
      },
      onError: () => {
        message.error("There was a problem adding call record");
      }
    }
  );

  return ReactDOM.createPortal(
    <>
      {meetingContext.showUpdateCallDetailsPopup && (
        <>
          <Draggable handle=".drag-handle">
            <FloatingContainer
              isMinimized={isMinimized}
              borderRed={borderRed}
              onMouseEnter={handleUserActivity} // Restart timer when re-entering
              onMouseLeave={() => {
                inactivityTimeout.current = setTimeout(() => {
                  setBorderRed(true);
                  stopTimer();
                }, 5000);
              }}
            >
              <FloatingCard
                title={
                  <div
                    className="drag-handle"
                    style={{ cursor: "grab", textAlign: "left" }}
                  >
                    Add Call Details <span>{formatTime(timer)}</span>
                  </div>
                }
                extra={
                  <>
                    <Button
                      type="text"
                      icon={
                        isMinimized ? <FullscreenOutlined /> : <MinusOutlined />
                      }
                      onClick={() => setIsMinimized(!isMinimized)}
                    />
                    <Button
                      type="text"
                      icon={<CloseOutlined />}
                      onClick={() =>
                        meetingContext.setShowUpdateCallDetailsPopup(false)
                      }
                    />
                  </>
                }
                style={{ width: "100%", height: "100%" }}
              >
                {isMinimized ? (
                  <></>
                ) : (
                  <OverflowCallRecordCardContainer>
                    <AddCallRecordGridContainer>
                      <div>
                        <Form.Item
                          name="date"
                          initialValue={selectedDraft?.date_p}
                        >
                          <CallRecordDate
                            clearForm={false}
                            selectedDraft={selectedDraft}
                            form={form}
                            disabled={
                              isFetchingCallRecords ||
                              selectedDraft?.callType !== CallTypes.MANUAL
                                ? true
                                : false
                            }
                          />
                        </Form.Item>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between"
                        }}
                      >
                        {/* <Form.Item name="duration" initialValue={selectedDraft?.duration}> */}
                        <div style={{ width: "49%" }}>
                          <Form.Item
                            name="duration"
                            initialValue={formattedTime(
                              Number(selectedDraft?.duration) / 60
                            )}
                          >
                            <CallRecordDuration
                              clearForm={false}
                              selectedDraft={selectedDraft}
                              form={form}
                              disabled={
                                isFetchingCallRecords ||
                                selectedDraft?.callType !== CallTypes.MANUAL
                                  ? true
                                  : true
                              }
                            />
                          </Form.Item>
                        </div>
                        <div
                          style={{
                            width: "49%",
                            alignItems: "center",
                            justifyContent: "flex-start"
                          }}
                        >
                          <DurationLabelText>Minutes</DurationLabelText>
                        </div>
                      </div>
                    </AddCallRecordGridContainer>

                    <Form.Item
                      name="discussionItems"
                      initialValue={selectedDraft?.notes}
                    >
                      {isLoadingCallRecordNotes ? (
                        <Spin />
                      ) : (
                        <CallRecordDiscussionItems
                          discussionItemOptions={callRecordNotesData || []}
                          clearForm={false}
                          selectedDraft={selectedDraft}
                          form={form}
                        />
                      )}
                    </Form.Item>
                    <Form.Item
                      name="otherIssues"
                      initialValue={selectedDraft?.desc}
                    >
                      <CallRecordOtherIssues
                        clearForm={false}
                        selectedDraft={selectedDraft}
                        form={form}
                        disabled={isFetchingCallRecords}
                        enableTypingTimer={false}
                      />
                    </Form.Item>
                    <ErrorMessage style={{ marginTop: "-20px" }}>
                      {errorMessage}
                    </ErrorMessage>
                    <ButtonContainer>
                      <AppButton
                        type={ButtonType.Primary}
                        onClick={() => {
                          form.submit();
                          // setSaveAsDraft(false);
                          handleSubmit();
                          meetingContext.updateFetchCallRecords(false);
                        }}
                        buttonContent={"Finalize"}
                        style={{ width: 120 }}
                        // loading={isLoadingPutCallRecord}
                        disabled={
                          isLoadingCallRecordNotes || isFetchingCallRecords
                        }
                      />
                    </ButtonContainer>
                  </OverflowCallRecordCardContainer>
                )}
              </FloatingCard>
            </FloatingContainer>
          </Draggable>
          <AppModal
            visible={submissionData ? true : false}
            title={
              status === "COMPLETED" ? "Finalize Submission" : "Save as Draft"
            }
            cancelText="No"
            okText="Yes"
            onCancel={() => {
              setSubmissionData(undefined);
            }}
            onOk={() => {
              if (submissionData) {
                handleOnFinish(submissionData);
                // setShowAddEditCallRecords("");
                setSubmissionData(undefined);
              }
              setSelectedDraft(undefined);
            }}
            prompt={{
              icon: (
                <InfoCircleOutlined
                  style={{ color: "#F5A623", fontSize: "40px" }}
                />
              ),
              text:
                status === "COMPLETED"
                  ? "You are going to create a call record that is non-editable, do you want to finalize?"
                  : "Do you want to save this call records as Draft?"
            }}
            footer
          />
        </>
      )}
    </>,
    FinalizeCallModalElement
  );
};

const FloatingContainer = styled.div<{
  isMinimized: boolean;
  borderRed: boolean;
}>`
  position: fixed;
  bottom: 50px;
  right: 50px;
  z-index: 1000;
  width: ${props => (props.isMinimized ? "290px" : "500px")};
  border: ${props =>
    props.borderRed ? "1px solid red" : "none"}; /* Red border if inactive */
  border-radius: 10px;
`;

const FloatingCard = styled(Card)`
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  border-radius: 10px;
`;

const AddCallRecordGridContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const DurationLabelText = styled.div`
  display: flex;
  padding-top: 10px;
  font-size: 14px;
`;

const OverflowCallRecordCardContainer = styled.div`
  max-height: calc(100vh - 350px);
  overflow: auto;
  padding-right: 3px;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
`;

const ErrorMessage = styled.div`
  display: grid;
  place-items: center;
  height: 100%;
  color: red;
  opacity: 0.3;
  font-weight: bold;
`;

export default FinalizeCallModal;
