import message from "antd/lib/message";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useParams } from "react-router";
import { createApiClient } from "apiClient";
import { AppButton } from "components/button";
import { ButtonType } from "components/button/appButton";
import { AppCard } from "components/card";
import { CommonErrorMessage } from "components/CommonErrorMessage";
import {
  MeetingContextState,
  MeetingModalContext,
  sessionStatusValues
} from "contextApis/meetingModalContext";
import {
  PatientContext,
  PatientContextState
} from "contextApis/patientContext";
import { UserContext, UserContextState } from "contextApis/userContext";
import { customRound } from "helpers/utils";
import { CallRecordBody, CallRecordFormData } from "sharedTypes";
import { CommonPatientExtensionItems } from "pages/patients/commonPatientExtensionItems";
import { AddCallRecord } from "./AddCallRecord";
import { CallRecord, CallRecordList } from "./CallRecordList";
import { EditCallRecord } from "./EditCallRecord";
import { CallRecordsTitleText, CallRecordsFlexContainer } from "./style";

export enum CallTypes {
  MANUAL = "Manual"
}

export interface SelectedCallRecordDraft {
  id: number;
  date: string;
  itemsDiscussed: string;
  duration: number;
  caller: string;
  other: string;
  callType: string;
}

interface PutCallRecordProps {
  patientId: string;
  callId: string | number;
  body: CallRecordBody;
}

export const CallRecordsPage: React.FC = () => {
  const userContext = useContext<UserContextState>(UserContext);
  const patientContext = useContext<PatientContextState>(PatientContext);
  const [showAddEditCallRecords, setShowAddEditCallRecords] = useState("");
  const [selectedDraft, setSelectedDraft] = useState<SelectedCallRecordDraft>();
  const meetingContext = useContext<MeetingContextState>(MeetingModalContext);
  const [changedDraft, setChangedDraft] = useState(false);
  const [callRecordList, setCallRecordList] = useState<CallRecord[]>();
  const [isLoadingCallRecordNotes, setIsLoadingCallRecordNotes] =
    useState(false);
  const [callRecordNotesData, setCallRecordNotesData] = useState();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<any>();
  const { id: userId } = useParams();

  // const { data: callRecordNotesData, isLoading: isLoadingCallRecordNotes } =
  //   useQuery("call-record-notes", async () => {
  //     return await createApiClient().getCallRecordNotes();
  //   });

  const getCallRecordNotes = async () => {
    const result: any = await createApiClient().getCallRecordNotes();
    setCallRecordNotesData(result);
  };

  useEffect(() => {
    getCallRecordNotes();
    getCallRecords();
  }, []);

  useEffect(() => {
    meetingContext.refreshCallRecords && getCallRecords();
    meetingContext.setRefreshCallRecordsStatus(false);
  }, [meetingContext.refreshCallRecords]);

  const isRemoteMonitoringEnabled =
    userContext.userData?.remote_monitoring.toLowerCase().trim() === "y";

  // const {
  //   isLoading,
  //   error,
  //   refetch: getCallRecords,
  //   isFetching: isFetchingCallRecords
  // } = useQuery<CallRecord[]>(
  //   "call-records",
  //   async () => {
  //     return await createApiClient().getCallRecords(userId ? userId : "", true);
  //   },
  //   {
  //     onSuccess: successData => {
  //       const tempData: {
  //         id: number;
  //         desc: string;
  //         notes: string;
  //         call_duration: number;
  //         prv_name: string;
  //         date_p: string;
  //         call_type: string;
  //         status: string;
  //         provider_internal_id: number | string;
  //       }[] = [...successData];

  //       tempData.map(item => {
  //         item.date_p = moment(moment.utc(item.date_p).toDate()).format(
  //           "MMMM DD, YYYY hh:mm A"
  //         );
  //         item.call_duration = customRound(item.call_duration);
  //         return item;
  //       });
  //       setCallRecordList(tempData);
  //       if (meetingContext.callRecordId) {
  //         meetingContext.setSessionStatus(sessionStatusValues.NOTSTARTED);
  //         const previouslySelectedDraft = tempData?.find(
  //           item => String(item.id) === meetingContext.callRecordId
  //         );
  //         const tempDraft = {
  //           id: previouslySelectedDraft?.id || 0,
  //           date: previouslySelectedDraft?.date_p || "",
  //           itemsDiscussed: previouslySelectedDraft?.desc || "",
  //           duration: previouslySelectedDraft?.call_duration || 0,
  //           caller: "",
  //           other: previouslySelectedDraft?.notes || "",
  //           callType: previouslySelectedDraft?.call_type || ""
  //         };
  //         meetingContext.setCallRecordId("");
  //         setSelectedDraft(tempDraft);
  //       }
  //     }
  //   }
  // );

  const getCallRecords = async () => {
    try {
      setIsLoading(true);
      const result: any = await createApiClient().getCallRecords(
        userId ? userId : "",
        true
      );
      console.log("ingetcallrecRESULT", result);
      const tempData: {
        id: number;
        desc: string;
        notes: string;
        call_duration: number;
        prv_name: string;
        date_p: string;
        call_type: string;
        status: string;
        provider_internal_id: number | string;
      }[] = [...result];

      tempData.map(item => {
        item.date_p = moment(moment.utc(item.date_p).toDate()).format(
          "MMMM DD, YYYY hh:mm A"
        );
        item.call_duration = customRound(item.call_duration);
        return item;
      });
      setCallRecordList(tempData);
      if (meetingContext.callRecordId) {
        meetingContext.setSessionStatus(sessionStatusValues.NOTSTARTED);
        const previouslySelectedDraft = tempData?.find(
          item => String(item.id) === meetingContext.callRecordId
        );
        const tempDraft = {
          id: previouslySelectedDraft?.id || 0,
          date: previouslySelectedDraft?.date_p || "",
          itemsDiscussed: previouslySelectedDraft?.desc || "",
          duration: previouslySelectedDraft?.call_duration || 0,
          caller: "",
          other: previouslySelectedDraft?.notes || "",
          callType: previouslySelectedDraft?.call_type || ""
        };
        meetingContext.setCallRecordId("");
        setSelectedDraft(tempDraft);
      }
    } catch (err) {
      console.log("inGetcallCatch", err);
      setError(err);
    } finally {
      setIsLoading(false);
    }
  };

  const { mutate: postCallRecord, isLoading: isLoadingPostCallRecord } =
    useMutation(createApiClient().postCallRecord, {
      onSuccess: () => {
        message.success("Successfully added call record");
        setTimeout(() => {
          getCallRecords();
        }, 1000);
        setShowAddEditCallRecords("");
        setSelectedDraft(undefined);
      },
      onError: () => {
        message.error("Failed to add call record");
      }
    });

  const { mutate: putCallRecord, isLoading: isLoadingPutCallRecords } =
    useMutation(
      "edit-call-record",
      async (props: PutCallRecordProps) => {
        /* eslint-disable */
        createApiClient().putCallRecord(
          props.patientId,
          props.callId,
          props.body
        );
        /* eslint-enable */
      },
      {
        onSuccess: () => {
          message.success("Call record details saved");
          setTimeout(() => {
            getCallRecords();
          }, 500);
        },
        onError: () => {
          message.error("There was a problem adding call record");
        }
      }
    );

  useEffect(() => {
    if (
      meetingContext.sessionStatus === sessionStatusValues.STARTED ||
      meetingContext.sessionStatus === sessionStatusValues.ENDED
    ) {
      if (meetingContext.sessionStatus === sessionStatusValues.STARTED) {
        setSelectedDraft(undefined);
      }
      if (meetingContext.sessionStatus === sessionStatusValues.ENDED) {
        const callRecordWithNoDuration = callRecordList?.find(
          item => !item.call_duration
        );

        //Set record, related to active call, to be highlighted on load of call record list
        if (selectedDraft) {
          meetingContext.setCallRecordId(String(callRecordWithNoDuration?.id));
        }
        setSelectedDraft(undefined);
      }
      setTimeout(() => {
        getCallRecords();
      }, 1000);
    }
  }, [meetingContext.sessionStatus]);

  function convertToSeconds(timeStr: any) {
    if (!timeStr.includes(":")) {
      const minutes = Number(timeStr);
      return minutes * 60;
    }
    const [minutes, seconds] = timeStr.split(":").map(Number);
    return minutes * 60 + seconds;
  }

  const onFormSubmit = (formData: CallRecordFormData) => {
    const callRecordBody: CallRecordBody = {
      callDateTime: moment.utc(formData.date).format("MM-DD-YYYY HH:mm"),
      callLength: convertToSeconds(formData?.duration),
      notes: [
        ...formData.discussionItems.map(item => ({
          desc: item.note_detail,
          id: item.id.toString(),
          isSelected: "true"
        })),
        { desc: formData.otherIssues, id: "999" }
      ],
      patient_id: patientContext.patientData?.id.toString() || "",
      provider_id: userContext.userData?.internal_id.toString() || "",
      typeOfCall: selectedDraft?.callType ? selectedDraft?.callType : "Manual",
      status: formData.status
    };
    if (formData.id) {
      putCallRecord({
        patientId: userId || "",
        callId: formData.id || "",
        body: callRecordBody
      });
    } else {
      postCallRecord(callRecordBody);
    }
  };

  return (
    <>
      <CommonPatientExtensionItems />
      <CallRecordsTitleText>
        Call Records
        {isRemoteMonitoringEnabled && (
          <AppButton
            buttonContent="Add Call Record"
            type={ButtonType.Primary}
            style={{ right: 10, bottom: 10, position: "absolute" }}
            onClick={() => {
              setShowAddEditCallRecords("add");
              setSelectedDraft(undefined);
            }}
            disabled={
              meetingContext.sessionStatus === sessionStatusValues.STARTED
            }
          />
        )}
      </CallRecordsTitleText>
      <CallRecordsFlexContainer>
        {isRemoteMonitoringEnabled ? (
          <CallRecordList
            setChangedDraft={setChangedDraft}
            data={callRecordList ? callRecordList : null}
            isLoading={
              isLoading ||
              // isFetchingCallRecords ||
              isLoadingCallRecordNotes ||
              !userContext.userData
            }
            errorMessage={
              error ? "There was an error in fetching the call record list" : ""
            }
            setSelectedDraft={setSelectedDraft}
            setShowAddEditCallRecords={setShowAddEditCallRecords}
            selectedDraft={selectedDraft ? selectedDraft : null}
          />
        ) : (
          <AppCard cardHeight="100%" cardWidth="100%">
            <CommonErrorMessage message="You do not have remote monitoring permissions, you are not authorized to access call records." />
          </AppCard>
        )}
        {showAddEditCallRecords === "add" && (
          <AddCallRecord
            onFormSubmit={onFormSubmit}
            setShowAddEditCallRecords={setShowAddEditCallRecords}
            isLoadingPostCallRecord={isLoadingPostCallRecord}
          />
        )}
        {showAddEditCallRecords === "edit" && selectedDraft && (
          <span style={{ width: "70%" }}>
            <EditCallRecord
              onFormSubmit={onFormSubmit}
              selectedDraft={selectedDraft}
              setShowAddEditCallRecords={setShowAddEditCallRecords}
              isLoadingPutCallRecord={isLoadingPutCallRecords}
              changedDraft={changedDraft}
              setChangedDraft={setChangedDraft}
              setSelectedDraft={setSelectedDraft}
              // disableForm={isFetchingCallRecords || isLoadingPutCallRecords}
              disableForm={isLoadingPutCallRecords}
              isLoadingCallRecordNotes={isLoadingCallRecordNotes}
              callRecordNotesData={callRecordNotesData || []}
              getCallRecords={getCallRecords}
            />
          </span>
        )}
      </CallRecordsFlexContainer>
    </>
  );
};
