import { EyeOutlined } from "@ant-design/icons";
import { Table, Space } from "antd";
import React, { useContext, useEffect } from "react";
import styled from "styled-components";
import { Colors } from "helpers/colors";
import { DateTimeFormat } from "helpers/dateTimeFormat";
import { AllBillsList } from "sharedTypes";
import Col from "antd/lib/col";
import { ColumnType } from "antd/lib/table";
import moment from "moment";
import { useQuery } from "react-query";
import {
  PatientContext,
  PatientContextState
} from "contextApis/patientContext";
import { createApiClient } from "apiClient";

interface ApprovedItemProps {
  data: AllBillsList[];
  setSelectedBillNumber(billNo: string): void;
  seletedBillNumber: string;
  dataDisableComponent: any;
}

export const ApprovedItem: React.FC<ApprovedItemProps> = ({
  data,
  setSelectedBillNumber,
  seletedBillNumber,
  dataDisableComponent
}) => {
  const filterDraftBill = data?.filter((bill: any) => bill.status !== "Draft");

  const convertDateToLocal = (value: string | Date, format?: string) => {
    return moment.utc(value, format).local();
  };

  const Reference = dataDisableComponent
    ? dataDisableComponent.vlan_base_care_plan === 1
      ? "Biller Reference"
      : "CDS Bill Reference"
    : "Biller Reference";

  const columns: ColumnType<AllBillsList>[] = [
    {
      title: "Date of Service",
      dataIndex: "dateOfService",
      key: "dateOfService",
      render: (text: string, Date) => (
        <DateTimeFormat
          date={convertDateToLocal(
            text,
            "M/D/YYYY H:mm:ss" || "M/D/YYYY" || "MM-DD-YYYY hh:mm A"
          )}
          hideTime
        />
      ),
      sorter: (a: AllBillsList, b: AllBillsList) =>
        Date.parse(b.dateOfService) - Date.parse(a.dateOfService),
      defaultSortOrder: "ascend"
    },
    {
      title: "ICD Code",
      dataIndex: "icdCode",
      key: "icdCode",
      render: (text: string[], record: AllBillsList) => (
        <BoldCol>
          {record?.diagnoses &&
            record?.diagnoses?.map((item, index) => {
              if (index === record?.diagnoses?.length - 1) {
                return item?.code;
              } else {
                return item?.code + ", ";
              }
            })}
        </BoldCol>
      )
    },
    {
      title: "CPT Code",
      dataIndex: "cptCode",
      key: "cptCode",
      render: (text: string[], record: AllBillsList) => (
        <BoldCol>
          {record?.diagnoses &&
            record?.charges?.map((item, index) => {
              if (index === record?.charges?.length - 1) {
                return item?.code;
              } else {
                return item?.code + ", ";
              }
            })}
        </BoldCol>
      )
    },
    {
      title: "Bill Status",
      dataIndex: "status",
      key: "status",
      width: 150,
      render: (text: string, record: AllBillsList) => (
        <Col>
          {record?.cds_code !== 200 && record?.cds_code !== null
            ? "Submission Error"
            : record.status == "Approve"
            ? "Submitted"
            : record.status}
        </Col>
      )
    },
    {
      title: "CareGem Bill Reference",
      dataIndex: "id",
      key: "id",
      render: (text: string, record: AllBillsList) => <Col>{record.id}</Col>
    },
    {
      title: Reference,
      dataIndex: "id",
      key: "id",
      render: (text: string, record: AllBillsList) => (
        <Col>
          {record.ref_uid == null || record.ref_uid == ""
            ? "-"
            : record.ref_uid}
        </Col>
      ),
      align: "center"
    },
    {
      title: "Provider Name",
      dataIndex: "providerName",
      key: "providerName",
      render: (text: string, record: AllBillsList) => (
        <Col>
          {record.providerName + " " + (record.degree ? record.degree : "")}
        </Col>
      )
    },
    {
      title: "View",
      dataIndex: "id",
      key: "actions",
      render: (text: string, record: AllBillsList) => (
        <ActionsCol>
          <Space size={20}>
            <EyeOutlined
              style={{
                fontSize: "25px",
                color: Colors.LightGrey,
                marginTop: "-3px"
              }}
              onClick={() => setSelectedBillNumber(record.id)}
            />
          </Space>
        </ActionsCol>
      )
    }
  ];

  return (
    <>
      <Container data-testid="approved-item">
        <Table
          dataSource={filterDraftBill}
          columns={columns}
          pagination={false}
          rowKey={record => record.id}
          onRow={record => ({
            onClick: () => setSelectedBillNumber(record.id),
            style: {
              background:
                seletedBillNumber === record.id ? Colors.Lavender : undefined
            }
          })}
        />
      </Container>
    </>
  );
};

const ActionsCol = styled(Col)`
  padding-right: 20px;
`;

const BoldCol = styled(Col)`
  font-family: Century Gothic Bold;
  color: ${Colors.Black};
`;

const Container = styled.div`
  background-color: ${Colors.White};
  border-bottom: 1px solid ${Colors.Lavender};
  font-size: 12px;
`;
