import Table, { ColumnsType } from "antd/lib/table";
import { range } from "lodash";
import React, { useContext, useState } from "react";
import { DateTime } from "luxon";
import { useQuery } from "react-query";
import { createApiClient } from "apiClient";
import moment from "moment";
import { useParams } from "react-router";
import { UserContext, UserContextState } from "contextApis/userContext";

interface DeviceDetailsType {
  imei: string;
  start_date: string;
  end_date: string;
}

interface DataType {
  PatientName: string;
  ProviderName: string;
  DeviceDetails: DeviceDetailsType[];
  Aug: string;
  Sep: string;
  Oct: string;
  Nov: string;
}

interface DataTypeTwo {
  Title: string;
  Jul: string;
  Aug: string;
  Sep: string;
  Oct: string;
  Nov: string;
  Dec: string;
}

const monthNames = [
  "JAN",
  "FEB",
  "MAR",
  "APR",
  "MAY",
  "JUN",
  "JUL",
  "AUG",
  "SEP",
  "OCT",
  "NOV",
  "DEC"
];

const getMonths = (
  currentMonth: number,
  startDateMonth: number,
  endDateMonth: number,
  startDateYear: number,
  endDateYear: number
) => {
  if (
    startDateMonth === -1 ||
    endDateMonth === -1 ||
    startDateYear === -1 ||
    endDateYear === -1
  ) {
    const fourthLastMonth = (currentMonth + monthsLength - 4) % monthsLength;
    const thirdLastMonth = (currentMonth + monthsLength - 3) % monthsLength;
    const secondLastMonth = (currentMonth + monthsLength - 2) % monthsLength;
    const lastMonth = (currentMonth + monthsLength - 1) % monthsLength;
    const nextMonth = (currentMonth + 1) % monthsLength;
    return [
      monthNames[fourthLastMonth],
      monthNames[thirdLastMonth],
      monthNames[secondLastMonth],
      monthNames[lastMonth],
      monthNames[currentMonth],
      monthNames[nextMonth]
    ];
  }
  const startDateMonthIndex = startDateMonth - 1;
  const endDateMonthIndex = endDateMonth - 1;

  if (startDateYear !== endDateYear) {
    const monthIndexRange = range(12);
    const startRange = monthIndexRange.filter(
      index => index >= startDateMonthIndex
    );
    const endRange = monthIndexRange.filter(
      index => index <= endDateMonthIndex
    );

    return [...startRange, ...endRange].map(index => monthNames[index]);
  }
  const monthRange = range(startDateMonthIndex, endDateMonthIndex + 1);
  return monthRange.map(index => monthNames[index]);
};

const monthsLength = monthNames.length;

function createDynamicDataObject(title: string) {
  const originalData: any = {
    Title: title
  };

  const currentDate = new Date();

  // Iterate over the last six months
  for (let i = 0; i < 6; i++) {
    const month = currentDate.toLocaleString("en-US", { month: "short" });
    originalData[month] = "";

    currentDate.setMonth(currentDate.getMonth() - 1);
  }

  return originalData;
}

const convertUTCDateToLocal = (dateString: string) => {
  const localDateTime = DateTime.fromISO(dateString, { zone: "utc" }).toLocal();
  const formattedDateTime = localDateTime.toFormat("LLL/dd/yyyy");
  return formattedDateTime;
};

export const RpmDetailTable = () => {
  const [startDate, setStartDate] = useState<DateTime | null>(null);
  const [endDate, setEndDate] = useState<DateTime | null>(null);
  const currentMonth = new Date().getMonth();

  const { id: patient_internal_id } = useParams();

  const userContext = useContext<UserContextState>(UserContext);

  function getSixMonthsAgoDate() {
    const currentDate = moment();
    const subtractedMonth = currentDate.subtract(6, "months");
    const result = subtractedMonth.set({
      hour: 0,
      minute: 0,
      second: 0,
      millisecond: 0
    });
    return result.toISOString();
  }

  const {
    isLoading,
    data: PatientBillingData,
    error: PatientBillingDataError
  } = useQuery(
    "PatientBillingData",
    async () => {
      const startDate = getSixMonthsAgoDate();
      const endDate = moment()
        .set({ hour: 23, minute: 59, second: 0, millisecond: 0 })
        .toISOString();
      return await createApiClient().getPatientBillingData(
        userContext?.userData?.id,
        Number(patient_internal_id),
        startDate,
        endDate
      );
    },
    {
      onSuccess: data => {
        console.log("PatientBillingData", data);
      }
    }
  );

  const isRPMenabled =
    PatientBillingData && PatientBillingData[0]?.patient_details?.length === 0
      ? false
      : true;

  const startDateMonth = startDate?.month || -1;
  const endDateMonth = endDate?.month || -1;
  const endDateYear = endDate?.year || -1;
  const startDateYear = startDate?.year || -1;

  const months = getMonths(
    currentMonth,
    startDateMonth,
    endDateMonth,
    startDateYear,
    endDateYear
  );
  const janIndex = months.indexOf("JAN");

  const headings = [
    "PATIENT NAME",
    "PROVIDER NAME",
    "DEVICE DETAILS",
    ...months.map((month, index) => {
      if (endDateYear === -1 || janIndex === -1 || janIndex === 0) return month;
      if (index < janIndex) return `${month} ${endDateYear - 1}`;
      return `${month} ${endDateYear}`;
    })
  ];

  const slicedVlaue = ["Title"];

  const slicedVlaues = headings.slice(3, 9);

  for (let i = 0; slicedVlaues.length > i; i++) {
    const c = slicedVlaues[i];
    const temp =
      c.charAt(0) +
      c.charAt(1).toLocaleLowerCase() +
      c.charAt(2).toLocaleLowerCase();

    slicedVlaue.push(temp);
  }

  const columnsTwo: ColumnsType<DataTypeTwo> = [
    {
      title: slicedVlaue[0],
      dataIndex: slicedVlaue[0],
      key: slicedVlaue[0],
      width: "40%",
      align: "center"
    },
    {
      title: slicedVlaue[1],
      dataIndex: slicedVlaue[1],
      key: slicedVlaue[1],
      align: "center",
      width: "10%"
    },
    {
      title: slicedVlaue[2],
      dataIndex: slicedVlaue[2],
      key: slicedVlaue[2],
      align: "center",
      width: "10%"
    },
    {
      title: slicedVlaue[3],
      dataIndex: slicedVlaue[3],
      key: slicedVlaue[3],
      align: "center",
      width: "10%"
    },
    {
      title: slicedVlaue[4],
      dataIndex: slicedVlaue[4],
      key: slicedVlaue[4],
      align: "center",
      width: "10%"
    },
    {
      title: slicedVlaue[5],
      dataIndex: slicedVlaue[5],
      key: slicedVlaue[5],
      align: "center",
      width: "10%"
    },
    {
      title: slicedVlaue[6],
      dataIndex: slicedVlaue[6],
      key: slicedVlaue[6],
      align: "center",
      width: "10%"
    }
  ];

  const columns: ColumnsType<DataType> = [
    {
      title: headings?.[0],
      dataIndex: "PatientName",
      key: "PatientName",
      width: "10%",
      align: "center"
    },
    {
      title: headings?.[1],
      dataIndex: "ProviderName",
      key: "ProviderName",
      width: "10%",
      align: "center"
    },
    {
      title: headings?.[2],
      dataIndex: "DeviceDetails",
      key: "DeviceDetails",
      render: (DeviceDetails: DeviceDetailsType[]) => {
        if (Array.isArray(DeviceDetails)) {
          return (
            <>
              {DeviceDetails.map((device, index) => (
                <div
                  key={index}
                  style={{
                    display: "flex",
                    flexDirection: "column"
                  }}
                >
                  <span>DeviceId: {device.imei},</span>

                  <span>
                    Start Date:{" "}
                    {!device.start_date
                      ? "-"
                      : // : moment(device.start_date).format("YYYY-MM-DD")}
                        convertUTCDateToLocal(device.start_date)}
                    ,
                  </span>
                  <span>
                    End Date:{" "}
                    {!device.end_date
                      ? "-"
                      : // : moment(device.end_date).format("YYYY-MM-DD")}
                        convertUTCDateToLocal(device.end_date)}
                  </span>
                </div>
              ))}
            </>
          );
        } else {
          return <div>Device Details is not available</div>;
        }
      },
      width: "20%",
      align: "center"
    },
    {
      title: headings?.[3],
      dataIndex: headings?.[3],
      key: headings?.[3],
      align: "center",
      width: "10%"
    },
    {
      title: headings?.[4],
      dataIndex: headings?.[4],
      key: headings?.[4],
      align: "center",
      width: "10%"
    },
    {
      title: headings?.[5],
      dataIndex: headings?.[5],
      key: headings?.[5],
      align: "center",
      width: "10%"
    },
    {
      title: headings?.[6],
      dataIndex: headings?.[6],
      key: headings?.[6],
      align: "center",
      width: "10%"
    },
    {
      title: headings?.[7],
      dataIndex: headings?.[7],
      key: headings?.[7],
      align: "center",
      width: "10%"
    },
    {
      title: headings?.[8],
      dataIndex: headings?.[8],
      key: headings?.[8],
      align: "center",
      width: "10%"
    }
  ];

  const DeviceDetails =
    PatientBillingData &&
    PatientBillingData[0]?.patient_details[0]?.paired_device_details;
  const PatientName =
    PatientBillingData &&
    PatientBillingData[0]?.patient_details[0]?.patient_name;
  const ProviderName =
    PatientBillingData && PatientBillingData[0]?.provider_name;

  const dataOne: DataType[] = [
    {
      PatientName: PatientName,
      ProviderName: ProviderName,
      DeviceDetails: DeviceDetails,
      Aug: "",
      Sep: "",
      Oct: "",
      Nov: ""
    }
  ];

  const monthNumberMap: any = {
    JAN: 1,
    FEB: 2,
    MAR: 3,
    APR: 4,
    MAY: 5,
    JUN: 6,
    JUL: 7,
    AUG: 8,
    SEP: 9,
    OCT: 10,
    NOV: 11,
    DEC: 12
  };

  const monthNumbers = slicedVlaue.map(
    (monthAbbrev: any) => monthNumberMap[monthAbbrev]
  );

  const c_records =
    PatientBillingData &&
    PatientBillingData[0]?.patient_details[0]?.call_records;

  const DoR =
    PatientBillingData &&
    PatientBillingData[0]?.patient_details[0]?.days_recording;

  const billing_details =
    PatientBillingData &&
    PatientBillingData[0]?.patient_details[0]?.billing_details;

  let val;

  if (Array.isArray(c_records)) {
    val = c_records.map((item: any) => item == monthNumbers);
  } else {
    console.log("c_records is not an array");
  }

  const orignalDataVersionOne = createDynamicDataObject("Minutes of Talk time");

  const valuesToReplace: Record<number, number> = c_records;

  const monthAbbreviations: Record<number, string> = {
    1: "Jan",
    2: "Feb",
    3: "Mar",
    4: "Apr",
    5: "May",
    6: "Jun",
    7: "Jul",
    8: "Aug",
    9: "Sep",
    10: "Oct",
    11: "Nov",
    12: "Dec"
  };

  const updatedData: any = { ...orignalDataVersionOne };

  for (const monthNumber in valuesToReplace) {
    const monthAbbrev = monthAbbreviations[parseInt(monthNumber, 10)];
    if (monthAbbrev && valuesToReplace[parseInt(monthNumber, 10)]) {
      const durationInSeconds = valuesToReplace[parseInt(monthNumber, 10)];
      const minutesValue = durationInSeconds / 60;
      const fullMinutes = Math.floor(minutesValue);
      const seconds = Math.round((minutesValue - fullMinutes) * 60);
      const formattedSeconds = seconds.toString().padStart(2, "0");
      const formattedDuration =
        seconds > 0 ? `${fullMinutes}:${formattedSeconds}` : `${fullMinutes}`;

      updatedData[monthAbbrev] = `${formattedDuration} min`;
    }
  }

  const dataTwo: DataTypeTwo[] = [];

  dataTwo.push(updatedData);

  const originalDataTwo = createDynamicDataObject("Days of Recording");

  DoR?.forEach((item: any) => {
    const startDate = new Date(item.end_date);
    const month = startDate.toLocaleString("en-US", { month: "short" });

    if (month in originalDataTwo) {
      originalDataTwo[month] = (
        // item.number_of_days + " " + moment(item.end_date).format("MMM/DD/YYYY");
        <span>
          <span>{item.number_of_days + " "}</span>
          <span style={{ color: "#1890ff" }}>
            {convertUTCDateToLocal(item.end_date)}
          </span>
        </span>
      );
    }
  });

  dataTwo.push(originalDataTwo);

  const originalDataThree = createDynamicDataObject("99453");

  billing_details?.forEach((item: any) => {
    if (item.billing_charge_code === "99453") {
      const date = new Date(item.date_of_service);
      const month = date.toLocaleString("en-US", { month: "short" });

      const parsedDate = DateTime.fromFormat(
        item.date_of_service,
        "EEE, dd LLL yyyy HH:mm:ss"
      );
      const outputDateString = parsedDate.toFormat("yyyy-MM-dd'T'HH:mm:ss");

      if (month in originalDataThree) {
        originalDataThree[month] +=
          // moment(item.date_of_service).format("MMM/DD/YYYY") + " ";
          convertUTCDateToLocal(outputDateString);
      }
    }
  });

  dataTwo.push(originalDataThree);

  const originalDataFour = createDynamicDataObject("99454");

  billing_details?.forEach((item: any) => {
    if (item.billing_charge_code === "99454") {
      const date = new Date(item.date_of_service);
      const month = date.toLocaleString("en-US", { month: "short" });

      const parsedDate = DateTime.fromFormat(
        item.date_of_service,
        "EEE, dd LLL yyyy HH:mm:ss"
      );
      const outputDateString = parsedDate.toFormat("yyyy-MM-dd'T'HH:mm:ss");

      if (month in originalDataFour) {
        originalDataFour[month] +=
          // moment(item.date_of_service).format("MMM/DD/YYYY") + " ";
          convertUTCDateToLocal(outputDateString) + " ";
      }
    }
  });

  dataTwo.push(originalDataFour);

  const originalDataFive = createDynamicDataObject("99457");

  const orignalDataFiveDuplicate = { ...originalDataFive };

  for (const monthNumber in valuesToReplace) {
    const monthAbbrev = monthAbbreviations[parseInt(monthNumber, 10)];
    if (monthAbbrev) {
      if (valuesToReplace[parseInt(monthNumber, 10)] / 60 > 20) {
        orignalDataFiveDuplicate[monthAbbrev] = (
          <span style={{ color: "red" }}>?</span>
        );
      }
    }
  }

  billing_details?.forEach((item: any) => {
    if (item.billing_charge_code === "99457") {
      const date = new Date(item.date_of_service);
      const month = date.toLocaleString("en-US", { month: "short" });

      const parsedDate = DateTime.fromFormat(
        item.date_of_service,
        "EEE, dd LLL yyyy HH:mm:ss"
      );
      const outputDateString = parsedDate.toFormat("yyyy-MM-dd'T'HH:mm:ss");

      if (month in originalDataFive) {
        originalDataFive[month] +=
          // moment(item.date_of_service).format("MMM/DD/YYYY") + " ";
          convertUTCDateToLocal(outputDateString) + " ";
      }
    }
  });

  const filteredData99457 = billing_details?.filter(
    (item: any) => item.billing_charge_code === "99457"
  );

  if (filteredData99457 && filteredData99457?.length !== 0) {
    dataTwo.push(originalDataFive);
  } else {
    dataTwo.push(orignalDataFiveDuplicate);
  }

  const originalDataSix = createDynamicDataObject("99458");

  const originalDataSixDuplicate = { ...originalDataSix };

  for (const monthNumber in valuesToReplace) {
    const monthAbbrev = monthAbbreviations[parseInt(monthNumber, 10)];
    if (monthAbbrev) {
      if (valuesToReplace[parseInt(monthNumber, 10)] / 60 > 40) {
        originalDataSixDuplicate[monthAbbrev] = (
          <span style={{ color: "red" }}>?</span>
        );
      }
    }
  }

  billing_details?.forEach((item: any) => {
    if (item.billing_charge_code === "99458") {
      const date = new Date(item.date_of_service);
      const month = date.toLocaleString("en-US", { month: "short" });

      const parsedDate = DateTime.fromFormat(
        item.date_of_service,
        "EEE, dd LLL yyyy HH:mm:ss"
      );
      const outputDateString = parsedDate.toFormat("yyyy-MM-dd'T'HH:mm:ss");

      if (month in originalDataSix) {
        originalDataSix[month] +=
          // moment(item.date_of_service).format("MMM/DD/YYYY") + " ";
          convertUTCDateToLocal(outputDateString) + " ";
      }
    }
  });

  const filteredData99458 = billing_details?.filter(
    (item: any) => item.billing_charge_code === "99458"
  );

  if (filteredData99458 && filteredData99458.length !== 0) {
    dataTwo.push(originalDataSix);
  } else {
    dataTwo.push(originalDataSixDuplicate);
  }

  return (
    <>
      {PatientBillingDataError ? (
        <div style={{ color: "red" }}>Error While Fetching RPM Details!</div>
      ) : (
        <>
          {isRPMenabled ? (
            <div>
              <Table
                columns={columns}
                dataSource={dataOne}
                pagination={false}
                bordered={true}
                loading={!PatientBillingData ? true : false}
              />
              <Table
                columns={columnsTwo}
                dataSource={dataTwo}
                showHeader={false}
                bordered={true}
                pagination={false}
              />
            </div>
          ) : (
            <div>Patient does not have RPM Enabled.</div>
          )}
        </>
      )}
    </>
  );
};
