import React, { useEffect, useState } from "react";
import Row from "antd/lib/row";
import styled, { CSSProperties } from "styled-components";
import { FixedSizeList as List } from "react-window";

import { Colors } from "helpers/colors";
import { NotificationListItem } from "sharedTypes";
import { NotificationsMenuItem } from "./notificationsMenuItem";

import { useLocation, useNavigate } from "react-router";
import { ProvidersRoutesRef } from "constants/routes";

interface NotificationsMenuProps {
  readonly avatarColSpan?: number;
  readonly getNotificationsList: () => void;
  readonly notifications: NotificationListItem[];
  readonly wrapperStyle?: CSSProperties;
  readonly loading?: boolean;
}

export const NotificationsMenuComponent: React.FC<NotificationsMenuProps> = ({
  notifications,
  avatarColSpan = 3,
  wrapperStyle,
  getNotificationsList,
  loading
}) => {
  const RowRenderer = ({ index, style }: any) => {
    const item = notifications[index];

    return (
      <RowStyle
        key={index}
        style={{
          ...style,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: item.status ? Colors.White : Colors.GhostWhite
        }}
        data-testid="notifications-menu-item"
        // onClick={() => redirectUser(item)}
      >
        <NotificationsMenuItem
          notificationItem={item}
          getNotificationsList={getNotificationsList}
          avatarColSpan={avatarColSpan}
          loading={loading}
          switchColSpan={2}
        />
      </RowStyle>
    );
  };

  return (
    <Wrapper style={wrapperStyle}>
      {notifications.length > 0 ? (
        <List
          itemCount={notifications.length}
          itemSize={100}
          width={"100%"}
          height={
            notifications.length * 75 > window.innerHeight
              ? window.innerHeight
              : notifications.length * 100
          }
        >
          {RowRenderer}
        </List>
      ) : (
        <EmptyDataText>No Data</EmptyDataText>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  height: 100%;
  ::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
`;

const RowStyle = styled(Row)`
  // padding-top: 13px;
  // padding-bottom: 13px;
  position: relative;
  // border-radius: 8px;
  padding-right: 8px;
  padding-left: 8px;

  :last-child {
    hr {
      display: none;
    }
  }
`;

const EmptyDataText = styled.div`
  display: grid;
  place-items: center;
  height: 100%;
`;
