import React, { useContext, useEffect } from "react";
import { useQuery } from "react-query";
import { createApiClient } from "apiClient";
import { CheckboxMenuCommonInterface } from "sharedTypes";

import { CommonPatientExtensionItems } from "pages/patients/commonPatientExtensionItems";
import { BillingContext, BillingContextState } from "./billingContext";
import { BillingTabs } from "./billingTabs";
import {
  PatientContext,
  PatientContextState
} from "contextApis/patientContext";

export const BillingPage: React.FC = () => {
  const billingContext = useContext<BillingContextState>(BillingContext);
  const patientContext = useContext<PatientContextState>(PatientContext);

  const {
    isLoading: isLoadingChargesList,
    data: chargesData,
    error: isChargesListError
  } = useQuery("charges-list", async () => {
    return await createApiClient().getChargesList(true);
  });

  const {
    isLoading: isLoadingDiagnosesList,
    data: diagnosesData,
    error: isDiagnosesListError
  } = useQuery("diagnoses-list", async () => {
    return await createApiClient().getDiagnosesList(true);
  });

  useEffect(() => {
    const renderCharges = !isLoadingChargesList && !isChargesListError;
    const renderDiagnoses = !isLoadingDiagnosesList && !isDiagnosesListError;

    if (renderDiagnoses) {
      const diagnosesList = diagnosesData?.map(
        (item: CheckboxMenuCommonInterface) => {
          item.checked = false;
          return item;
        }
      );
      if (diagnosesList) {
        billingContext.setDiagnosesMenuItems(diagnosesList);
      }
    }

    if (renderCharges) {
      const chargesList = chargesData?.map(
        (item: CheckboxMenuCommonInterface) => {
          item.checked = false;
          return item;
        }
      );
      if (chargesList) {
        billingContext.setChargesMenuItems(chargesList);
      }
    }
  }, []);

  return (
    <>
      <CommonPatientExtensionItems />
      <BillingTabs />
    </>
  );
};
