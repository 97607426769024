import moment from "moment";
import { BloodPressureVitals } from "sharedTypes";

interface SeriesData {
  name: string;
  readonly data: [number | string, number][];
}

export const bloodPressureMapper = ({
  data
}: {
  data: BloodPressureVitals[];
}) => {
  const am: SeriesData[] = [
    { name: "", data: [] },
    { name: "", data: [] }
  ];
  const pm: SeriesData[] = [
    { name: "", data: [] },
    { name: "", data: [] }
  ];

  const sortedData = data?.sort((a: any, b: any) => {
    const a_bp_taken_on = moment
      .utc(a.bp_taken_on, "MM-DD-YYYY hh:mm A")
      .local();
    const b_bp_taken_on = moment
      .utc(b.bp_taken_on, "MM-DD-YYYY hh:mm A")
      .local();

    const dateA = a_bp_taken_on.valueOf();
    const dateB = b_bp_taken_on.valueOf();

    return dateA === dateB ? a.key_id - b.key_id : dateA - dateB;
  });

  sortedData?.forEach(item => {
    const bpTakenOn = moment
      .utc(item.bp_taken_on, "MM-DD-YYYY hh:mm A")
      .local()
      .valueOf();

    if (item.am_bp) {
      am[0].name = "AM Sys";
      am[0].data.push([
        String(bpTakenOn),
        Number(item.am_bp ? item.am_bp.split("/")[0] : 0)
      ]);

      am[1].name = "AM Dias";
      am[1].data.push([
        String(bpTakenOn),
        Number(item.am_bp ? item.am_bp.split("/")[1] : 0)
      ]);
    }

    if (item.pm_bp) {
      pm[0].name = "PM Sys";
      pm[0].data.push([
        String(bpTakenOn),
        Number(item.pm_bp ? item.pm_bp.split("/")[0] : 0)
      ]);

      pm[1].name = "PM Dias";
      pm[1].data.push([
        String(bpTakenOn),
        Number(item.pm_bp ? item.pm_bp.split("/")[1] : 0)
      ]);
    }
  });

  return { data: [], series: [am, pm] };
};
