import Input from "antd/lib/input";
import React from "react";
import styled from "styled-components";
import { useEffect } from "react";
import { Colors } from "helpers/colors";
import { CircleIndicator } from "components/circleIndicator";

interface TextInputProps {
  readonly label: string;
  readonly value?: string;
  readonly placeholder?: string;
  readonly onChange?: (value: string) => void;
  readonly style?: React.CSSProperties;
  readonly labelStyles?: React.CSSProperties;
  textStyles?: React.CSSProperties;
  readonly labelZIndex?: number;
  readonly disabled?: boolean;
  readonly handleMouseLeave?: (
    e: React.MouseEvent<HTMLTextAreaElement, MouseEvent>
  ) => void;
  readonly isTyping?: boolean;
  readonly typedTimerValue?: any;
  readonly enableTypingTimer?: boolean;
}

interface PasswordInputProps extends TextInputProps {
  iconRender?: (visible: boolean) => React.ReactNode;
}

const StyledInput = styled(Input)`
  border-radius: 8px;
  height: 40px;
  padding: 18px 5px 8px 10px;
`;

const StyledTextArea = styled(Input.TextArea)`
  border-radius: 8px;
  padding-top: 15px;
`;

export const LabelContainer = styled.div`
  position: absolute;
  z-index: 1;
`;

export const LabelText = styled.div`
  font-size: 10px;
  color: #979dab;
  font-weight: 500;
  margin-left: 10px;
  margin-top: 3px;
`;

// export const StyledSuffix = styled.div`
//   height: 44px;
//   width: 44px;
//   padding: 8px 10px 8px 20px;
// `;
export const TextInput: React.FC<TextInputProps> = ({
  label,
  value,
  placeholder,
  onChange,
  style,
  labelStyles,
  textStyles,
  disabled
}) => {
  const onValueChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    onChange ? onChange(event.target.value) : false;

  return (
    <div style={style}>
      <LabelContainer style={labelStyles}>
        <LabelText>{label}</LabelText>
      </LabelContainer>
      <StyledInput
        value={value}
        placeholder={placeholder}
        onChange={onValueChange}
        style={textStyles}
        disabled={disabled}
      />
    </div>
  );
};

export const TextAreaInput: React.FC<TextInputProps> = ({
  label,
  value,
  placeholder,
  onChange,
  style,
  labelStyles,
  disabled,
  handleMouseLeave,
  isTyping,
  typedTimerValue,
  enableTypingTimer
}) => {
  const onValueChange = (event: React.ChangeEvent<HTMLTextAreaElement>) =>
    onChange ? onChange(event.target.value) : false;

  function formatTime(seconds: any) {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}m ${remainingSeconds}s`;
  }

  return (
    <div style={style}>
      {enableTypingTimer && (
        <LabelText style={{ marginLeft: "72%" }}>
          Activity Duration: <b>{formatTime(typedTimerValue.toFixed(0))}</b>
        </LabelText>
      )}

      <LabelContainer style={labelStyles}>
        <LabelText>{label}</LabelText>
      </LabelContainer>
      {enableTypingTimer && (
        <LabelText
          style={{
            position: "absolute",
            zIndex: 999,
            marginLeft: "100.5%",
            marginTop: -2
          }}
        >
          {isTyping ? (
            <CircleIndicatorWrapper>
              <CircleIndicator outerColor={"#00ff00"} innerColor={"#00ff00"} />
            </CircleIndicatorWrapper>
          ) : (
            <CircleIndicatorWrapper>
              <CircleIndicator
                outerColor={Colors.Red}
                innerColor={Colors.Red}
              />
            </CircleIndicatorWrapper>
          )}
        </LabelText>
      )}
      <StyledTextArea
        value={value}
        autoSize={{ minRows: 3 }}
        placeholder={placeholder}
        onChange={onValueChange}
        disabled={disabled}
        onMouseLeave={handleMouseLeave}
      />
    </div>
  );
};

const StyledPassword = styled(Input.Password)`
  border-radius: 8px;
  height: 40px;
  padding: 18px 5px 8px 10px;
  .ant-input-suffix {
    font-weight: bold;
    padding: 0px 10px 10px 0;
  }
`;

const CircleIndicatorWrapper = styled.span`
  position: absolute;
  top: 9px;
  right: 7px;
`;

export const PasswordInput: React.FC<PasswordInputProps> = ({
  label,
  value,
  placeholder,
  onChange,
  style,
  iconRender,
  labelStyles,
  textStyles
}) => {
  const onValueChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    onChange ? onChange(event.target.value) : false;

  return (
    <div style={style}>
      <LabelContainer style={labelStyles}>
        <LabelText>{label}</LabelText>
      </LabelContainer>
      <StyledPassword
        value={value}
        placeholder={placeholder}
        onChange={onValueChange}
        iconRender={iconRender}
        style={textStyles}
      />
    </div>
  );
};
