import { FormInstance } from "antd";
import React, { useEffect, useState } from "react";
import { AppTextInput } from "components/inputs";
import { SelectedCallRecordDraft } from "pages/callRecords/callRecords";

export interface CallRecordDurationProps {
  readonly duration?: string;
}

interface AddCallRecordFormProps {
  readonly value?: CallRecordDurationProps;
  readonly onChange?: (value: CallRecordDurationProps) => void;
  readonly clearForm: boolean;
  readonly selectedDraft?: SelectedCallRecordDraft;
  readonly form?: FormInstance;
  readonly disabled?: boolean;
}

export const CallRecordDuration: React.FC<AddCallRecordFormProps> = ({
  value,
  onChange,
  clearForm,
  selectedDraft,
  form,
  disabled
}) => {
  const [duration, setDuration] = useState<number | any>();

  const formattedTime = (minutes: any) => {
    const fullMinutes = Math.floor(minutes);
    const seconds = Math.round((minutes - fullMinutes) * 60);
    const formattedSeconds = seconds.toString().padStart(2, "0");
    return `${fullMinutes}:${formattedSeconds}`;
  };

  useEffect(() => {
    if (selectedDraft) {
      if (form) {
        form.setFieldsValue({
          duration: { duration: formattedTime(selectedDraft.duration / 60) }
        });
      }
      // setDuration(String(selectedDraft.duration));
      setDuration(formattedTime(Number(selectedDraft?.duration) / 60));
    }
  }, [selectedDraft]);

  const triggerChange = (changedValue: CallRecordDurationProps) => {
    onChange?.({
      duration,
      ...value,
      ...changedValue
    });
  };

  const onDurationChange = (newDuration: string) => {
    if (!isNaN(Number(newDuration))) {
      // setDuration(newDuration);
      setDuration(Number(newDuration));

      if (form) {
        form.setFieldsValue({ duration: { duration: newDuration } });
      }
      triggerChange({ duration: newDuration });
    }
  };

  useEffect(() => {
    if (clearForm) {
      if (form) {
        form.setFieldsValue({ duration: { duration: "" } });
      }
      setDuration("");
    }
  }, [clearForm]);

  return (
    <AppTextInput
      value={duration}
      label="Duration"
      onChange={onDurationChange}
      placeholder="Enter Duration"
      disabled={disabled}
    />
  );
};
