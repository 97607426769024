import Row from "antd/lib/row";
import Col from "antd/lib/col";
import React, { useContext, useEffect, useLayoutEffect, useState } from "react";
import styled from "styled-components";

import { Colors } from "helpers/colors";
import { AllBillsList } from "sharedTypes";
import { ApprovedItem } from "./approvedItem";
import { ViewBill } from "./ViewBill";
import { createApiClient } from "apiClient";
import { useQuery } from "react-query";
import {
  PatientContext,
  PatientContextState
} from "contextApis/patientContext";

interface TabListProps {
  readonly data: AllBillsList[];
}

export const ApprovedTabList: React.FC<TabListProps> = ({ data }) => {
  const patientContext = useContext<PatientContextState>(PatientContext);

  const {
    data: dataDisableComponent,
    isLoading: isLoadingDisableComponent,
    refetch: refetchDisableComponent
  } = useQuery(
    "disableComponent",
    async () => {
      if (patientContext.orgId) {
        return createApiClient().getDisableContent(patientContext.orgId);
      } else {
        throw new Error("orgId is missing");
      }
    },
    {
      enabled: !!patientContext.orgId
    }
  );

  useLayoutEffect(() => {
    if (patientContext.orgId) {
      refetchDisableComponent();
    }
  }, [patientContext.orgId]);

  const [seletedBillNumber, setSelectedBillNumber] = useState("");

  const selectedItem = data.find(item => item.id === seletedBillNumber);

  return (
    <Row gutter={[30, 0]}>
      <Col span={seletedBillNumber ? 15 : 24}>
        <ListContainer>
          {data.length <= 0 && <div>No bills associated with this patient</div>}
          {data.length > 0 && (
            <ApprovedItem
              data={data}
              setSelectedBillNumber={setSelectedBillNumber}
              seletedBillNumber={seletedBillNumber}
              dataDisableComponent={dataDisableComponent}
            />
          )}
        </ListContainer>
      </Col>
      <Col span={selectedItem ? 9 : 0}>
        {selectedItem && (
          <ViewBill
            data={selectedItem}
            setSelectedItem={setSelectedBillNumber}
          />
        )}
      </Col>
    </Row>
  );
};

const ListContainer = styled.div`
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.05), 0 0px 10px rgba(0, 0, 0, 0.08);
  border-radius: 0px 0px 8px 8px;
  margin-top: -2px;
  padding: 12px 12px 12px 12px;
  background-color: ${Colors.White};
  max-height: 70vh;
  overflow: scroll;
`;
