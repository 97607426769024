import React, { useState } from "react";
import { EyeOutlined } from "@ant-design/icons";
import styled from "styled-components";
import moment from "moment";

import { Dropdown } from "components/dropDownBox";
import { Colors } from "helpers/colors";
import { DateTimeFormat } from "helpers/dateTimeFormat";
import { BloodPressureVitals } from "sharedTypes";
import { dateToLocal } from "helpers/utils";

interface BloodPressureDetailsDropdownProps {
  readonly reportedBy: string;
  readonly reportedOn: string;
  readonly bloodPressureVitals: BloodPressureVitals;
  readonly typeFilter: string;
}

export const BloodPressureDetailsDropdown: React.FC<
  BloodPressureDetailsDropdownProps
> = ({ reportedBy, reportedOn, bloodPressureVitals, typeFilter }) => {
  const [showDetailsDropdown, setShowDetailsDropdown] = useState(false);

  const convertUTCDateToLocal = (dateString: any) => {
    const localDateTime = moment.utc(dateString).local();

    const formattedDateTime = localDateTime.format("MMM DD YYYY");
    return formattedDateTime;
  };

  const convertDateToLocal = (value: string | Date, format?: string) => {
    return moment.utc(value, format).local();
  };

  return (
    <Dropdown
      arrow
      borderradius="8px"
      trigger={["click"]}
      placement="bottomRight"
      dropDownMenuHeader={<DropdownHeader>View Details</DropdownHeader>}
      dropDownMenuBody={
        <BodyContainer>
          <BodyTitle>Reported By</BodyTitle>
          <ReportedByRow>{reportedBy ? reportedBy : "Remote"}</ReportedByRow>

          <div style={{ display: "flex", fontSize: 8 }}>
            <BodyTitle>Reported On : </BodyTitle>{" "}
            <ReportedOnContent style={{ marginLeft: 4, marginTop: -1.2 }}>
              {!bloodPressureVitals.reported_on ? (
                <DateTimeFormat
                  date={moment
                    .utc(bloodPressureVitals.bp_taken_on, "MM-DD-YYYY hh:mm A")
                    .local()}
                />
              ) : (
                <DateTimeFormat
                  date={moment
                    .utc(bloodPressureVitals.reported_on, "MM-DD-YYYY hh:mm A")
                    .local()}
                />
              )}
            </ReportedOnContent>
          </div>

          <div style={{ display: "flex", marginBottom: 10 }}>
            <BodyTitle>
              Reported with {typeFilter === "Remote" ? "Remote" : "Vital"} Signs
              :{" "}
            </BodyTitle>
            <div style={{ marginLeft: 4, marginTop: -1.2 }}>
              {!bloodPressureVitals.reported_on ? (
                <DateTimeFormat
                  date={moment
                    .utc(bloodPressureVitals.bp_taken_on, "MM-DD-YYYY hh:mm A")
                    .local()}
                />
              ) : (
                <DateTimeFormat
                  date={moment
                    .utc(bloodPressureVitals.bp_taken_on, "MM-DD-YYYY hh:mm A")
                    .local()}
                />
              )}
            </div>
          </div>
          <div>
            {bloodPressureVitals.bp_taken_time
              ? `When : ${bloodPressureVitals.bp_taken_time}`
              : ""}
          </div>
          <div>
            {bloodPressureVitals.pos
              ? `Posture : ${bloodPressureVitals.pos}`
              : ""}
          </div>
          <div>
            {bloodPressureVitals.symptoms
              ? `BP symptoms or comments : ${bloodPressureVitals.symptoms}`
              : ""}
          </div>
          <div>
            {bloodPressureVitals.am_systolic_top
              ? `AM Syst : ${bloodPressureVitals.am_systolic_top}`
              : ""}
          </div>
          <div>
            {bloodPressureVitals.am_diastolic_bottom
              ? `AM Dias : ${bloodPressureVitals.am_diastolic_bottom}`
              : ""}
          </div>
          <div>
            {bloodPressureVitals.pm_systolic_top
              ? `PM Syst : ${bloodPressureVitals.pm_systolic_top}`
              : ""}
          </div>
          <div>
            {bloodPressureVitals.pm_diastolic_bottom
              ? `PM Dias : ${bloodPressureVitals.pm_diastolic_bottom}`
              : ""}
          </div>
          <div>
            {bloodPressureVitals.hr_taken_date
              ? `HR taken on : ${convertUTCDateToLocal(
                  bloodPressureVitals.hr_taken_date
                )}`
              : ""}
          </div>
          <div>
            {bloodPressureVitals.h_pulse
              ? `HR : ${bloodPressureVitals.h_pulse}`
              : ""}
          </div>
          {/* {splitData[0].data.map(item => {
            if (Object.keys(item).length > 0) {
              return (
                <div key={item.key}>
                  {item.key}: {item.value}
                </div>
              );
            } else {
              return null;
            }
          })} */}
        </BodyContainer>
      }
      visible={showDetailsDropdown}
      setVisible={setShowDetailsDropdown}
    >
      <EyeOutlined
        style={{ fontSize: "20px", color: Colors.LightGrey }}
        onClick={() => setShowDetailsDropdown(!showDetailsDropdown)}
      />
    </Dropdown>
  );
};

const ReportedOnContent = styled.div`
  font-size: 14px;
  margin-bottom: 10px;
`;

const ReportedByRow = styled.div`
  font-size: 14px;
  font-family: Century Gothic Bold;
  margin-bottom: 10px;
`;

const BodyTitle = styled.div`
  color: ${Colors.gothicRegular};
  font-size: 12px;
`;

const BodyContainer = styled.div`
  background-color: ${Colors.GhostWhite};
  padding: 15px;
  padding-left: 20px;
  border-radius: 0px 0px 8px 8px;
  width: 531px;
`;

const DropdownHeader = styled.div`
  font-family: Century Gothic Bold;
  font-size: 14px;
  padding: 20px;
  height: 60px;
  background-color: ${Colors.White};
  border-radius: 8px 8px 0px 0px;
  border-bottom: 2px solid ${Colors.Lavender};
`;
